/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom"; // Import Link
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import "./Admin.css";
const Admin = () => {
  let user_id = localStorage.getItem("user_id");
  let token = localStorage.getItem("token");

  const [activeSubmenu, setActiveSubmenu] = useState(null); // State to manage active submenu
  const [users, setUsers] = useState([]);
  const [products, setProducts] = useState([]);
  const [error, setError] = useState(null);
  const [showMenu, setShowMenu] = useState(1);
  const [username, setUsername] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [country, setCountry] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [logoImage, setLogoImage] = useState(null);
  const [productImage, setProductImage] = useState([]);
  const [showEditModal, setShowEditModal] = useState(false);
  const [currentProduct, setCurrentProduct] = useState(null);
  const [success, setSuccess] = useState(null);
  const navigate = useNavigate();
  const [imageUrls, setImageUrls] = useState({});
  const [selectedFile, setSelectedFile] = useState(null);
  const [showModal, setShowModal] = useState(false); // To control modal visibility
  const [editUser, setEditUser] = useState(null); // Store current user to be edited
  const [productName, setProductName] = useState("");
  const [productDescription, setProductDescription] = useState("");
  const [category, setCategory] = useState("");
  const [price, setPrice] = useState("");
  const [cost, setCost] = useState("");
  const [stock, setStock] = useState("");
  const [formData, setFormData] = useState({
    username: "",
    user_email: "",
    user_type: "",
    phone_number: "",
    user_address: "",
    user_country: "",
    user_postal_code: "",
  });
  const [images, setImages] = useState([]);
  const [orders, setOrders] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const domainname = "http://www.just-one-price.com:3000";

  const ImageModal = ({ images, isOpen, onClose }) => {
    if (!isOpen) return null; // Don't render if the modal is closed

    return (
      <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
        <div className="bg-white p-6 rounded-lg max-w-4xl w-full shadow-lg">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-xl font-semibold">Product Images</h2>
            <button
              onClick={onClose}
              className="text-red-500 hover:text-red-700 transition-colors text-xl"
            >
              Close
            </button>
          </div>
          <div className="grid grid-cols-4 gap-4 justify-items-center overflow-auto max-h-96">
            {images.map((image, index) => (
              <img
                key={index}
                src={image}
                alt={`Product image ${index}`}
                className="w-29 h-29 object-cover rounded border-4 border-grey-500 hover:border-gray-700 transition-colors "
              />
            ))}
          </div>
        </div>
      </div>
    );
  };
  const handleUpdateStatus = async (orderId, newStatus) => {
    try {
      const response = await fetch(
        `${domainname}/order/orders/${orderId}/status`, // Updated URL
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            status: newStatus,
            user_id: user_id,
            token: token,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update status");
      }

      alert(`Order status updated to ${newStatus}!`);
      await fetchOrders(); // Refresh the orders list
      // Optionally, refresh the orders list here
    } catch (error) {
      console.error("Error updating status:", error);
      alert("Failed to update status.");
    }
  };
  const fetchOrders = async () => {
    try {
      const response = await fetch(`${domainname}/order/orders/admin`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user_id: user_id,
          token: token,
        }),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();

      // Check if data is an array
      if (Array.isArray(data)) {
        // Sort orders by order_id on initial load
        setOrders(data.sort((a, b) => a.order_id - b.order_id));
      } else {
        console.error("Expected an array but received:", data);
        // Handle the case where data is not an array
        setOrders([]); // or some other default state
      }
    } catch (error) {
      console.error("Error fetching orders:", error);
      // Handle the error appropriately
      setOrders([]); // or some other default state
    }
  };
  const handleUpdatePaymentStatus = async (orderId, newPaymentStatus) => {
    try {
      const response = await fetch(
        `${domainname}/order/orders/${orderId}/status`, // Updated URL
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            payment_status: newPaymentStatus,
            user_id: user_id,
            token: token,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update payment status");
      }

      alert(`Payment status updated to ${newPaymentStatus}!`);
      await fetchOrders(); // Refresh the orders list
      // Optionally, refresh the orders list here
    } catch (error) {
      console.error("Error updating payment status:", error);
      alert("Failed to update payment status.");
    }
  };

  const fetchImagesAndShowModal = async (productCode) => {
    try {
      const response = await fetch(
        `${domainname}/product/products/images/allImages/default/${productCode}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
          },
        }
      );

      if (response.ok) {
        const imageData = await response.json();

        // Map base64 images to URLs that can be rendered in the <img> tags
        const imageUrls = imageData.map((image) => {
          return `data:image/png;base64,${image.image_binary}`; // Ensure the MIME type matches your image (e.g., image/png or image/jpeg)
        });

        setImages(imageUrls); // Set the fetched images to display
        setIsModalOpen(true); // Open the modal
      } else {
        const errorText = await response.text();
        console.error("Failed to fetch images:", response.status, errorText);
      }
    } catch (error) {
      console.error("Error fetching images:", error);
    }
  };

  const handleEditClick = (user) => {
    setEditUser(user); // Set the user to be edited
    setFormData({
      username: user.username || "",
      user_email: user.user_email || "",
      user_type: user.user_type || "",
      phone_number: user.phone_number || "",
      user_address: user.user_address || "",
      user_country: user.user_country || "",
      user_postal_code: user.user_postal_code || "",
    });
    setShowModal(true); // Show the modal
  };
  // Handle form changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "logo_image") {
      setSelectedFile(e.target.files[0]); // Handle file selection
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value, // Dynamically update form fields
      }));
    }
  };

  const handleSubmitEdit = async (e) => {
    e.preventDefault();
    try {
      if (!editUser) return;
      const user_id = localStorage.getItem("user_id");
      const token = localStorage.getItem("token");
      // Create a new FormData object
      const formDataToSubmit = new FormData();

      // Append fields from formData
      for (const key in formData) {
        if (formData.hasOwnProperty(key)) {
          formDataToSubmit.append(key, formData[key]);
        }
      }

      // Append the file if it exists
      if (selectedFile) {
        formDataToSubmit.append("logo_image", selectedFile); // Append the selected file
      }

      // Send the PUT request
      const response = await fetch(
        `${domainname}/user/update/${user_id}/${encodeURIComponent(token)}/${
          editUser.user_id
        }`,
        {
          method: "PUT",
          body: formDataToSubmit, // Use the new FormData object
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update user");
      }

      const updatedUser = await response.json();

      // Update users list with the edited user
      setUsers((prevUsers) =>
        prevUsers.map((u) =>
          u.user_id === updatedUser.user_id ? updatedUser : u
        )
      );
      toast.success("User updated successfully!"); // Show success toast
      setShowModal(false); // Close the modal on success
      window.location.reload();
    } catch (error) {
      console.error("Error updating user:", error);
      setError("Failed to update user. Please try again.");
    }
  };

  const fetchImage = async (userCode) => {
    const user_id = localStorage.getItem("user_id");
    const token = localStorage.getItem("token");
    try {
      const response = await fetch(
        `${domainname}/user/logo/${user_id}/${encodeURIComponent(
          token
        )}/${userCode}`
      );
      if (response.ok) {
        const blob = await response.blob();
        const url = URL.createObjectURL(blob);
        setImageUrls((prev) => ({ ...prev, [userCode]: url }));
      } else {
        // console.error("Failed to fetch image:", response.statusText);
      }
    } catch (error) {
      //console.error("Error fetching image:", error);
    }
  };
  useEffect(() => {
    const fetchUsers = async () => {
      const user_id = localStorage.getItem("user_id");
      const token = localStorage.getItem("token");

      try {
        const response = await fetch(
          //`http://localhost:3000/user/getusers/`
          `${domainname}/user/getusers/${user_id}/
            ${encodeURIComponent(token)}`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();

        setUsers(data); // Assuming the response data is an array of users
        setImageUrls({});
        data.forEach((user) => {
          if (user.user_code) {
            fetchImage(user.user_code);
          }
        });
      } catch (error) {
        setError("Failed to fetch users");
        console.error("Error fetching users:", error);
      }
    };

    fetchUsers();
  }, []);

  useEffect(() => {
    return () => {
      Object.values(imageUrls).forEach((url) => URL.revokeObjectURL(url));
    };
  }, [imageUrls]);

  useEffect(() => {
    const fetchProducts = async () => {
      const user_id = localStorage.getItem("user_id");
      const token = localStorage.getItem("token");

      try {
        const response = await fetch(
          `${domainname}/product/getTopproducts/${user_id}/${encodeURIComponent(
            token
          )}`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setProducts(data.products); // Assuming the response data is an array of users
      } catch (error) {
        setError("Failed to fetch users");
        console.error("Error fetching users:", error);
      }
    };

    fetchProducts();
  }, []);
  useEffect(() => {
    const fetchOrders = async () => {
      const user_id = localStorage.getItem("user_id");
      try {
        const response = await fetch(`${domainname}/order/orders/admin`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            user_id: user_id,
            token: token,
          }),
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();

        // Check if data is an array
        if (Array.isArray(data)) {
          // Sort orders by order_id on initial load
          setOrders(data.sort((a, b) => a.order_id - b.order_id));
        } else {
          console.error("Expected an array but received:", data);
          // Handle the case where data is not an array
          setOrders([]); // or some other default state
        }
      } catch (error) {
        console.error("Error fetching orders:", error);
        // Handle the error appropriately
        setOrders([]); // or some other default state
      }
    };

    fetchOrders();
  }, []);

  const handleFileChange = (event) => {
    setLogoImage(event.target.files[0]);
  };
  const handleProductFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files); // Convert FileList to an array
    setProductImage(selectedFiles); // Store the array of files in state
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    const user_id = localStorage.getItem("user_id");
    const token = localStorage.getItem("token");
    // Create a FormData object to handle file upload
    const formData = new FormData();
    formData.append("username", username);
    formData.append("user_email", userEmail);
    formData.append("password_hash", password);
    formData.append("phone_number", phoneNumber);
    formData.append("user_address", address);
    formData.append("user_country", country);
    formData.append("user_postal_code", postalCode);
    if (logoImage) formData.append("logo_image", logoImage);

    try {
      const response = await fetch(
        `${domainname}/user/users/createuser/${user_id}/${encodeURIComponent(
          token
        )}`,
        {
          method: "POST",
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error(
          "Network response was not ok. User was not created successfully."
        );
      }
      toast.success("User created successfully!"); // Show success toast
      window.location.reload();
      setSuccess("User created successfully!");
      // Clear the form or handle further actions
      setUsername("");
      setUserEmail("");
      setPassword("");
      setPhoneNumber("");
      setAddress("");
      setCountry("");
      setPostalCode("");
      setLogoImage(null);
    } catch (error) {
      setError("Failed to create user.");
    }
  };
  const handleSubmitProduct = async (event) => {
    event.preventDefault();
    const userId = localStorage.getItem("user_id");
    const token = localStorage.getItem("token");
    // Create a FormData object to handle file upload
    if (userId == null || token == null) {
      setError("Authentication required. Please log in.");
      return;
    }
    const formData = new FormData();
    formData.append("user_id", userId); // Add user_id to the form data
    formData.append("product_name", productName);
    formData.append("product_description", productDescription);
    formData.append("product_cost", cost);
    formData.append("product_price", price);
    formData.append("product_category", category);
    formData.append("available_quantity", stock);

    if (Array.isArray(productImage) && productImage.length > 0) {
      productImage.forEach((image) => {
        formData.append("images", image);
      });
    } else {
      setError("Please upload at least one image.");
      return;
    }
    try {
      const response = await fetch(
        `${domainname}/product/addProduct/${userId}/${encodeURIComponent(
          token
        )}`, // Include the userId in the endpoint
        {
          method: "POST",
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error(
          "Network response was not ok. Product was not created successfully."
        );
      }
      toast.success("Product created successfully!"); // Show success toast
      
      setSuccess("Product created successfully!");

      // Clear the form or handle further actions
      setProductName("");
      setPrice("");
      setCost("");
      setCategory("");
      setStock("");
      setProductDescription("");
      setProductImage([]);
      window.location.reload();
    } catch (error) {
      setError("Failed to create product.");
    }
  };
  const deleteUser = async (userId) => {
    try {
      // const user = localStorage.getItem("user");
      const user_id = localStorage.getItem("user_id");
      const token = localStorage.getItem("token");
      const response1 = await fetch(
        `${domainname}/user/users/deactivate/${user_id}/${token}/${userId}`,
        {
          method: "PATCH", // Ensure the HTTP method matches the server's expectations
          headers: {
            "Content-Type": "application/json",
            // Include other headers if needed (e.g., authentication tokens)
          },
        }
      );
      if (!response1.ok) {
        throw new Error(
          "Network response was not ok.  User was not deleted successfully."
        );
      }
      toast.success("User deactivated successfully!"); // Show success toast
      window.location.reload();
    } catch (error) {
      setError("Failed to delete user");
      console.error("Error deleting user:", error);
    }
  };
  const deleteAllImages = async (product_id) => {
    // Confirm if the user really wants to delete all images
    const isConfirmed = window.confirm(
      "Are you sure you want to delete all images for this product?"
    );

    if (!isConfirmed) {
      return; // Exit if the user cancels the confirmation
    }

    // Retrieve the user_id and token from localStorage for authentication
    const user_id = localStorage.getItem("user_id");
    const token = localStorage.getItem("token");

    try {
      // Make the DELETE request to remove all images for the specified product
      const deleteImagesResponse = await fetch(
        `${domainname}/product/deleteImages/${product_id}/${user_id}/${encodeURIComponent(
          token
        )}`,
        {
          method: "DELETE",
        }
      );

      // Check if the response indicates success
      if (!deleteImagesResponse.ok) {
        const errorText = await deleteImagesResponse.text(); // Get response text
        console.error("Delete response error:", errorText); // Log the error response
        throw new Error(
          "Failed to delete images. Network response was not ok."
        );
      }

      // Optionally, fetch updated product data or images after deletion
      const updatedProductResponse = await fetch(
        `${domainname}/product/getTopproducts/${user_id}/${encodeURIComponent(
          token
        )}`
      );

      if (!updatedProductResponse.ok) {
        throw new Error("Failed to fetch updated product data.");
      }

      const updatedProductData = await updatedProductResponse.json();
      setProducts(updatedProductData.products); // Update the state with new product data

      // Optionally display a success message or toast
      alert("All images were deleted successfully.");
    } catch (error) {
      console.error("Error deleting images:", error);
      alert("An error occurred while deleting images.");
    }
  };

  const deleteProduct = async (product_id) => {
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this product?"
    );

    if (!isConfirmed) {
      return; // Exit if not confirmed
    }

    const user_id = localStorage.getItem("user_id");
    const token = localStorage.getItem("token");

    try {
      const response1 = await fetch(
        `${domainname}/product/deleteProduct/${product_id}/${user_id}/${encodeURIComponent(
          token
        )}`,
        {
          method: "DELETE",
        }
      );
      if (!response1.ok) {
        throw new Error(
          "Network response was not ok. Product was not deleted successfully."
        );
      }

      const response2 = await fetch(
        `${domainname}/product/getTopproducts/${user_id}/${encodeURIComponent(
          token
        )}`
      );
      if (!response2.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response2.json();
      setProducts(data.products); // Assuming the response data is an array of products
    } catch (error) {
      setError("Failed to delete product");
      console.error("Error deleting product:", error);
    }
  };
  const editProduct = async (product_id, updatedProductData) => {
    const user_id = localStorage.getItem("user_id");
    const token = localStorage.getItem("token");

    try {
      // Make the PUT request to edit the product
      const response = await fetch(
        `${domainname}/product/editProduct/${user_id}/${encodeURIComponent(
          token
        )}/${product_id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json", // Set the content type to JSON
          },
          body: JSON.stringify(updatedProductData), // Send the updated product data as JSON
        }
      );

      if (!response.ok) {
        throw new Error(
          "Network response was not ok. Product was not edited successfully."
        );
      }
      toast.success("Product edited successfully!", {
        autoClose: 1000, // Set the toast to auto-close after 3000 milliseconds (3 seconds)
      });
      // Optionally re-fetch the products list after editing
      const response2 = await fetch(
        `${domainname}/product/getTopproducts/${user_id}/${encodeURIComponent(
          token
        )}`
      );

      if (!response2.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response2.json();
      setProducts(data.products); // Assuming the response data is an array of products
    } catch (error) {
      setError("Failed to edit product");
      console.error("Error editing product:", error);
    }
  };

  const activateProduct = async (product_id) => {
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this product?"
    );

    if (!isConfirmed) {
      return; // Exit if not confirmed
    }

    const user_id = localStorage.getItem("user_id");
    const token = localStorage.getItem("token");

    try {
      const response1 = await fetch(
        `${domainname}/product/activateProduct/${product_id}/${user_id}/${encodeURIComponent(
          token
        )}`,
        {
          method: "PUT",
        }
      );
      if (!response1.ok) {
        throw new Error(
          "Network response was not ok. Product was not deleted successfully."
        );
      }

      const response2 = await fetch(
        `${domainname}/product/getTopproducts/${user_id}/${encodeURIComponent(
          token
        )}`
      );
      if (!response2.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response2.json();
      setProducts(data.products); // Assuming the response data is an array of products
    } catch (error) {
      setError("Failed to delete product");
      console.error("Error deleting product:", error);
    }
  };
  const handleSubmenuToggle = (menu) => {
    setActiveSubmenu(activeSubmenu === menu ? null : menu);
  };

  const handleCustomerPage = () => {
    navigate("/"); // Redirect to the default page
  };
  const openEditModal = (product) => {
    setCurrentProduct(product);
    setShowEditModal(true);
  };

  const userTypeMap = {
    1: "Admin",
    2: "Staff",
    3: "Partner",
    4: "Customer",
  };
  const formatSingaporePhoneNumber = (phoneNumber) => {
    if (!phoneNumber) return "Not provided";

    // Ensure the number is a string and remove any non-digit characters
    const cleaned = ("" + phoneNumber).replace(/\D/g, "");

    // Format: +65 8863 6441
    if (cleaned.length === 8) {
      return `+65 ${cleaned.slice(0, 4)} ${cleaned.slice(4)}`;
    }

    return phoneNumber; // Return original if format fails
  };
  return (
    <div className="App">
      <aside
        id="logo-sidebar"
        className={`fixed top-0 left-0 z-40 w-96 h-screen transition-transform ${"-translate-x-full"} sm:translate-x-0`}
        aria-label="Sidebar"
      >
        <div className="h-full px-6 py-4 overflow-y-auto bg-gray-50 dark:bg-gray-800">
          <a className="flex items-center justify-center ps-2.5 mb-8">
            <span className="self-center text-3xl font-semibold whitespace-nowrap dark:text-white text-center">
              Administration
            </span>
          </a>

          <ul className="space-y-3 font-semibold">
            <li>
              <button
                onClick={() => handleSubmenuToggle("users")}
                className="flex items-center p-3 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group w-full text-left"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="size-9"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M18 18.72a9.094 9.094 0 0 0 3.741-.479 3 3 0 0 0-4.682-2.72m.94 3.198.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0 1 12 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 0 1 6 18.719m12 0a5.971 5.971 0 0 0-.941-3.197m0 0A5.995 5.995 0 0 0 12 12.75a5.995 5.995 0 0 0-5.058 2.772m0 0a3 3 0 0 0-4.681 2.72 8.986 8.986 0 0 0 3.74.477m.94-3.197a5.971 5.971 0 0 0-.94 3.197M15 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm-13.5 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z"
                  />
                </svg>

                <span className="flex-1 ms-4 text-2xl">Users</span>
                <svg
                  className={`w-6 h-6 transition-transform ${
                    activeSubmenu === "users" ? "rotate-180" : "rotate-0"
                  }`}
                  aria-hidden="true"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </button>
              {activeSubmenu === "users" && (
                <ul className="pl-4 mt-2 space-y-2">
                  <li>
                    <Link
                      onClick={() => setShowMenu(1)}
                      className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
                    >
                      <span className="flex-1 ms-4">Get All Users</span>
                    </Link>
                  </li>

                  <li>
                    <Link
                      onClick={() => setShowMenu(1.1)}
                      className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
                    >
                      <span className="flex-1 ms-4">Create User</span>
                    </Link>
                  </li>
                </ul>
              )}
            </li>

            <li>
              <button
                onClick={() => handleSubmenuToggle("products")}
                className="flex items-center p-3 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group w-full text-left"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="size-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="m7.875 14.25 1.214 1.942a2.25 2.25 0 0 0 1.908 1.058h2.006c.776 0 1.497-.4 1.908-1.058l1.214-1.942M2.41 9h4.636a2.25 2.25 0 0 1 1.872 1.002l.164.246a2.25 2.25 0 0 0 1.872 1.002h2.092a2.25 2.25 0 0 0 1.872-1.002l.164-.246A2.25 2.25 0 0 1 16.954 9h4.636M2.41 9a2.25 2.25 0 0 0-.16.832V12a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 12V9.832c0-.287-.055-.57-.16-.832M2.41 9a2.25 2.25 0 0 1 .382-.632l3.285-3.832a2.25 2.25 0 0 1 1.708-.786h8.43c.657 0 1.281.287 1.709.786l3.284 3.832c.163.19.291.404.382.632M4.5 20.25h15A2.25 2.25 0 0 0 21.75 18v-2.625c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125V18a2.25 2.25 0 0 0 2.25 2.25Z"
                  />
                </svg>

                <span className="flex-1 ms-4 text-2xl">Products</span>
                <svg
                  className={`w-6 h-6 transition-transform ${
                    activeSubmenu === "products" ? "rotate-180" : "rotate-0"
                  }`}
                  aria-hidden="true"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </button>
              {activeSubmenu === "products" && (
                <ul className="pl-4 mt-2 space-y-2">
                  <li>
                    <Link
                      onClick={() => setShowMenu(2)}
                      className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
                    >
                      <span className="flex-1 ms-4">All My Products</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
                      onClick={() => setShowMenu(2.1)}
                    >
                      <span className="flex-1 ms-4">Add Product</span>
                    </Link>
                  </li>
                </ul>
              )}
            </li>

            <li>
              <button
                onClick={() => handleSubmenuToggle("orders")}
                className="flex items-center p-3 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group w-full text-left"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="size-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 0 0-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 0 0-16.536-1.84M7.5 14.25 5.106 5.272M6 20.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Zm12.75 0a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Z"
                  />
                </svg>

                <span className="flex-1 ms-4 text-2xl">Orders</span>
                <svg
                  className={`w-6 h-6 transition-transform ${
                    activeSubmenu === "orders" ? "rotate-180" : "rotate-0"
                  }`}
                  aria-hidden="true"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </button>
              {activeSubmenu === "orders" && (
                <ul className="pl-4 mt-2 space-y-2">
                  <li>
                    <Link
                      onClick={() => setShowMenu(3)}
                      className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
                    >
                      <span className="flex-1 ms-4">All Orders</span>
                    </Link>
                  </li>
                </ul>
              )}
            </li>
            <li>
              <button
                onClick={() => handleSubmenuToggle("partners")}
                className="flex items-center p-3 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group w-full text-left"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="size-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M12.75 3.03v.568c0 .334.148.65.405.864l1.068.89c.442.369.535 1.01.216 1.49l-.51.766a2.25 2.25 0 0 1-1.161.886l-.143.048a1.107 1.107 0 0 0-.57 1.664c.369.555.169 1.307-.427 1.605L9 13.125l.423 1.059a.956.956 0 0 1-1.652.928l-.679-.906a1.125 1.125 0 0 0-1.906.172L4.5 15.75l-.612.153M12.75 3.031a9 9 0 0 0-8.862 12.872M12.75 3.031a9 9 0 0 1 6.69 14.036m0 0-.177-.529A2.25 2.25 0 0 0 17.128 15H16.5l-.324-.324a1.453 1.453 0 0 0-2.328.377l-.036.073a1.586 1.586 0 0 1-.982.816l-.99.282c-.55.157-.894.702-.8 1.267l.073.438c.08.474.49.821.97.821.846 0 1.598.542 1.865 1.345l.215.643m5.276-3.67a9.012 9.012 0 0 1-5.276 3.67m0 0a9 9 0 0 1-10.275-4.835M15.75 9c0 .896-.393 1.7-1.016 2.25"
                  />
                </svg>

                <span className="flex-1 ms-4 text-2xl">Partners</span>
                <svg
                  className={`w-6 h-6 transition-transform ${
                    activeSubmenu === "partners" ? "rotate-180" : "rotate-0"
                  }`}
                  aria-hidden="true"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </button>
              {activeSubmenu === "partners" && (
                <ul className="pl-4 mt-2 space-y-2">
                  <li>
                    <Link
                      to="/Admin/PartnerManage/Get"
                      className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
                    >
                      <span className="flex-1 ms-4">Get Partners</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/Admin/PartnerManage/Update"
                      className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
                    >
                      <span className="flex-1 ms-4">Update Partner</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/Admin/PartnerManage/Delete"
                      className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
                    >
                      <span className="flex-1 ms-4">Delete Partner</span>
                    </Link>
                  </li>
                </ul>
              )}
            </li>
          </ul>

          <button
            onClick={handleCustomerPage} // Updated handler
            className="flex items-center mt-8 p-3 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group w-full text-left"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-7 w-7"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M15 15l-2 5L9 9l11 4-5 2zm0 0l5 5M7.188 2.239l.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656l-2.12 2.122"
              />
            </svg>
            <span className="flex-1 ms-4 text-2xl">Customer Page</span>{" "}
            {/* Adjusted text size */}
          </button>
        </div>
      </aside>
      <div className="p-6">
        {" "}
        {error && <p className="text-red-500">{error}</p>}
        {showMenu === 1 && (
          <div className="relative overflow-x-auto shadow-lg sm:rounded-lg">
            <table
              className="w-full text-sm text-left text-gray-500 dark:text-gray-400"
              style={{ position: "relative", left: "20%" }} // Adjust positioning as needed
            >
              <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-800 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-4 py-3">
                    Username
                  </th>
                  <th scope="col" className="px-4 py-3">
                    Email
                  </th>
                  <th scope="col" className="px-4 py-3">
                    User Type
                  </th>
                  <th scope="col" className="px-4 py-3">
                    User Code
                  </th>
                  <th scope="col" className="px-4 py-3">
                    Phone Number
                  </th>
                  <th scope="col" className="px-4 py-3">
                    Address
                  </th>
                  <th scope="col" className="px-4 py-3">
                    Country
                  </th>
                  <th scope="col" className="px-4 py-3">
                    Postal Code
                  </th>
                  <th scope="col" className="px-4 py-3">
                    Deactivated?
                  </th>
                  <th scope="col" className="px-4 py-3">
                    Logo
                  </th>
                  <th scope="col" className="px-4 py-3">
                    Action
                  </th>
                </tr>
              </thead>

              {users.length > 0 ? (
                <tbody>
                  {users.map((user, index) => (
                    <tr
                      key={user.user_id}
                      className={`bg-white border-b dark:bg-gray-900 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-700 ${
                        index % 2 === 0 ? "bg-gray-50" : "bg-white"
                      }`}
                    >
                      <td className="px-4 py-3">{user.username}</td>
                      <td className="px-4 py-3">{user.user_email}</td>
                      <td className="px-4 py-3">
                        {userTypeMap[user.user_type] || "Unknown"}
                      </td>
                      <td className="px-4 py-3">
                        {user.user_code || "Not provided"}
                      </td>
                      <td className="px-4 py-3">
                        {formatSingaporePhoneNumber(user.phone_number) ||
                          "Not provided"}
                      </td>
                      <td className="px-4 py-3">
                        {user.user_address || "Not provided"}
                      </td>
                      <td className="px-4 py-3">
                        {user.user_country || "Not provided"}
                      </td>
                      <td className="px-4 py-3">
                        {user.user_postal_code || "Not provided"}
                      </td>
                      <td className="px-4 py-3">
                        {user.deactivated ? "Yes" : "No"}
                      </td>
                      <td className="px-4 py-3">
                        {imageUrls[user.user_code] ? (
                          <img
                            src={imageUrls[user.user_code]}
                            alt="Logo"
                            className="h-12 w-12 rounded-full shadow-sm object-cover"
                          />
                        ) : (
                          "No"
                        )}
                      </td>
                      <td className="px-4 py-3 flex space-x-2">
                        {user.user_type !== 1 ? (
                          <a
                            onClick={() => handleEditClick(user)}
                            className="text-blue-600 hover:underline"
                          >
                            Edit
                          </a>
                        ) : (
                          <span className="text-gray-400 cursor-not-allowed">
                            Edit
                          </span>
                        )}
                        <a
                          onClick={() => deleteUser(user.user_id)}
                          className="text-red-600 dark:text-red-500 hover:underline"
                        >
                          Remove
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              ) : (
                <tbody>
                  <tr>
                    <td colSpan={11} className="text-center text-gray-600 py-4">
                      No users found
                    </td>
                  </tr>
                </tbody>
              )}
            </table>
          </div>
        )}
        {showMenu === 1.1 && (
          <div className="relative overflow-x-auto">
            <section className="bg-white dark:bg-gray-900">
              <div className="max-w-2xl px-4 py-8 mx-auto lg:py-16 mt-10">
                <h2 className="mb-4 text-4xl font-bold text-gray-900 dark:text-white">
                  Create User
                </h2>
                <form onSubmit={handleSubmit}>
                  <div className="grid gap-4 mb-4 sm:grid-cols-2 sm:gap-6 sm:mb-5">
                    <div className="sm:col-span-2">
                      <label
                        htmlFor="username"
                        className="block mb-2 text-xl font-medium text-gray-900 dark:text-white"
                      >
                        Username
                      </label>
                      <input
                        type="text"
                        name="username"
                        id="username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                        placeholder="Enter username"
                        required
                      />
                    </div>

                    <div className="w-full">
                      <label
                        htmlFor="user_email"
                        className="block mb-2 text-xl font-medium text-gray-900 dark:text-white"
                      >
                        Email
                      </label>
                      <input
                        type="email"
                        name="user_email"
                        id="user_email"
                        value={userEmail}
                        onChange={(e) => setUserEmail(e.target.value)}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                        placeholder="Enter email"
                        required
                      />
                    </div>

                    <div className="w-full">
                      <label
                        htmlFor="password_hash"
                        className="block mb-2 text-xl font-medium text-gray-900 dark:text-white"
                      >
                        Password
                      </label>
                      <input
                        type="password"
                        name="password_hash"
                        id="password_hash"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                        placeholder="Enter password"
                        required
                      />
                    </div>

                    <div className="w-full">
                      <label
                        htmlFor="phone_number"
                        className="block mb-2 text-xl font-medium text-gray-900 dark:text-white"
                      >
                        Phone Number
                      </label>
                      <input
                        type="tel"
                        name="phone_number"
                        id="phone_number"
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                        placeholder="Enter phone number"
                        required
                      />
                    </div>

                    <div className="w-full">
                      <label
                        htmlFor="user_address"
                        className="block mb-2 text-xl font-medium text-gray-900 dark:text-white"
                      >
                        Address
                      </label>
                      <input
                        type="text"
                        name="user_address"
                        id="user_address"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                        placeholder="Enter address"
                        required
                      />
                    </div>

                    <div className="w-full">
                      <label
                        htmlFor="user_country"
                        className="block mb-2 text-xl font-medium text-gray-900 dark:text-white"
                      >
                        Country
                      </label>
                      <input
                        type="text"
                        name="user_country"
                        id="user_country"
                        value={country}
                        onChange={(e) => setCountry(e.target.value)}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                        placeholder="Enter country"
                        required
                      />
                    </div>

                    <div className="w-full">
                      <label
                        htmlFor="user_postal_code"
                        className="block mb-2 text-xl font-medium text-gray-900 dark:text-white"
                      >
                        Postal Code
                      </label>
                      <input
                        type="text"
                        name="user_postal_code"
                        id="user_postal_code"
                        value={postalCode}
                        onChange={(e) => setPostalCode(e.target.value)}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                        placeholder="Enter postal code"
                        required
                      />
                    </div>

                    <div className="w-full sm:col-span-2">
                      <label
                        htmlFor="logo_image"
                        className="block mb-2 text-xl font-medium text-gray-900 dark:text-white"
                      >
                        Profile Picture (optional)
                      </label>
                      <input
                        type="file"
                        name="logo_image"
                        id="logo_image"
                        onChange={handleFileChange}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                      />
                    </div>
                  </div>

                  <div className="flex items-center space-x-4">
                    <button
                      type="submit"
                      className="text-white bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                    >
                      Create User
                    </button>
                  </div>
                </form>
                {error && <p className="text-red-500 mt-4">{error}</p>}
                {success && <p className="text-green-500 mt-4">{success}</p>}
              </div>
            </section>
          </div>
        )}
        {showMenu === 2 && (
          <div className="relative overflow-x-auto">
            <table
              style={{ position: "relative", left: "20%" }}
              className="w-full text-lg text-left text-gray-500 dark:text-gray-400"
            >
              <thead className="text-lg text-gray-900 bg-gray-100 dark:bg-gray-700 dark:text-gray-300">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    Product Code
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Product Name
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Product Description
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 whitespace-nowrap overflow-hidden text-ellipsis"
                  >
                    Product Cost
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 whitespace-nowrap overflow-hidden text-ellipsis"
                  >
                    Product Price
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 whitespace-nowrap overflow-hidden text-ellipsis"
                  >
                    Product Category
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 whitespace-nowrap overflow-hidden text-ellipsis"
                  >
                    Product Quantity
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Deactivated?
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Activate
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Image Gallery
                  </th>
                  <th
                    scope="col"
                    className="px-8 py-3 whitespace-nowrap overflow-hidden text-ellipsis"
                  >
                    Remove All Images
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Deactivate
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Edit
                  </th>
                </tr>
              </thead>

              {products.length > 0 ? (
                <tbody className="bg-white dark:bg-gray-800 divide-y divide-gray-200">
                  {products.map((product, index) => (
                    <tr
                      key={index}
                      className="hover:bg-gray-100 dark:hover:bg-gray-600 transition-all"
                    >
                      <td className="px-6 py-4">{product.product_code}</td>
                      <td className="px-6 py-4">{product.product_name}</td>
                      <td className="px-6 py-4">
                        {product.product_description}
                      </td>
                      <td className="px-6 py-4">${product.product_cost}</td>
                      <td className="px-6 py-4">${product.product_price}</td>
                      <td className="px-6 py-4">{product.product_category}</td>
                      <td className="px-6 py-4">
                        {product.available_quantity}
                      </td>
                      <td className="px-6 py-4">
                        {product.deactivated ? "Yes" : "No"}
                      </td>
                      <td className="px-6 py-4">
                        <button
                          className="flex items-center space-x-2 text-white-100 hover:text-green-400 transition-colors"
                          onClick={() => activateProduct(product.product_id)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-8 w-8"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            stroke-width="2"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z"
                            />
                          </svg>
                          <span>Activate</span>
                        </button>
                      </td>
                      <td className="px-6 py-4">
                        <button
                          className="flex items-center space-x-2 text-blue-200 hover:text-blue-600 transition-colors"
                          onClick={() => {
                            if (
                              !product.image_ids ||
                              product.image_ids.length === 0
                            ) {
                              toast.info(
                                "No images available for this product."
                              ); // Show toast notification
                            } else {
                              fetchImagesAndShowModal(product.product_code);
                            }
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-8 w-8"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth="2"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
                            />
                          </svg>
                          <span>View</span>
                        </button>
                      </td>
                      <td className="px-6 py-4">
                        <button
                          className="flex items-center space-x-2 text-red-200 hover:text-red-600 transition-colors"
                          onClick={() => deleteAllImages(product.product_id)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-8 w-8"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth="2"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                            />
                          </svg>
                          <span className="whitespace-nowrap overflow-hidden text-ellipsis">
                            Remove All Images
                          </span>
                        </button>
                      </td>
                      <td className="px-6 py-4">
                        <button
                          className="flex items-center space-x-2 text-red-400 hover:text-red-600 transition-colors"
                          onClick={() => deleteProduct(product.product_id)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-8 w-8"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            stroke-width="2"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
                            />
                          </svg>
                          <span className="whitespace-nowrap overflow-hidden text-ellipsis">
                            Deactivate
                          </span>
                        </button>
                      </td>

                      <td className="px-6 py-4">
                        <button
                          className="flex items-center space-x-2 text-yellow-500 hover:text-yellow-600 transition-colors"
                          onClick={() => openEditModal(product)} // Add function to open modal with the selected product's data
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-8 w-8"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth="2"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                            />
                          </svg>
                          <span>Edit</span>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              ) : (
                <p className="text-center py-4 text-gray-500">
                  No products found
                </p>
              )}
            </table>
          </div>
        )}
        {showMenu === 2.1 && (
          <div className="relative overflow-x-auto">
            <section className="bg-white dark:bg-gray-900">
              <div className="max-w-2xl px-4 py-8 mx-auto lg:py-16 ">
                <h2 className="mb-4 text-4xl font-bold text-gray-900 dark:text-white">
                  Add New Product
                </h2>
                <form onSubmit={handleSubmitProduct}>
                  <div className="grid gap-4 mb-4 sm:grid-cols-2 sm:gap-6 sm:mb-5">
                    <div className="sm:col-span-2">
                      <label
                        htmlFor="product_name"
                        className="block mb-2 text-xl font-medium text-gray-900 dark:text-white"
                      >
                        Product Name
                      </label>
                      <input
                        type="text"
                        name="product_name"
                        id="product_name"
                        value={productName}
                        onChange={(e) => setProductName(e.target.value)}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                        placeholder="Enter product name"
                        required
                      />
                    </div>

                    <div className="sm:col-span-2 ">
                      <label
                        htmlFor="product_description"
                        className="block mb-2 text-xl font-medium text-gray-900 dark:text-white"
                      >
                        Product Description
                      </label>
                      <textarea
                        name="product_description"
                        id="product_description"
                        value={productDescription}
                        onChange={(e) => setProductDescription(e.target.value)}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                        placeholder="Enter product description"
                        required
                      />
                    </div>

                    <div className="w-full">
                      <label
                        htmlFor="price"
                        className="block mb-2 text-xl font-medium text-gray-900 dark:text-white"
                      >
                        Product Price
                      </label>
                      <input
                        type="number"
                        name="price"
                        id="price"
                        value={price}
                        onChange={(e) => setPrice(e.target.value)}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                        placeholder="Enter product price"
                        required
                      />
                    </div>

                    <div className="w-full">
                      <label
                        htmlFor="cost"
                        className="block mb-2 text-xl font-medium text-gray-900 dark:text-white"
                      >
                        Product Cost
                      </label>
                      <input
                        type="number"
                        name="cost"
                        id="cost"
                        value={cost}
                        onChange={(e) => setCost(e.target.value)}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                        placeholder="Enter product cost"
                        required
                      />
                    </div>
                    <div className="w-full">
                      <label
                        htmlFor="category"
                        className="block mb-2 text-xl font-medium text-gray-900 dark:text-white"
                      >
                        Category
                      </label>
                      <input
                        type="text"
                        name="category"
                        id="category"
                        value={category}
                        onChange={(e) => setCategory(e.target.value)}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                        placeholder="Enter product category"
                        required
                      />
                    </div>
                    <div className="w-full">
                      <label
                        htmlFor="stock"
                        className="block mb-2 text-xl font-medium text-gray-900 dark:text-white"
                      >
                        Stocks
                      </label>
                      <input
                        type="number"
                        name="stock"
                        id="stock"
                        value={stock}
                        onChange={(e) => setStock(e.target.value)}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                        placeholder="Enter stock quantity"
                        required
                      />
                    </div>

                    <div className="flex items-center justify-center w-full sm:col-span-2">
                      <label
                        htmlFor="product_image"
                        className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer transition duration-300 ease-in-out bg-gray-50 dark:bg-gray-700 hover:bg-gray-200 hover:border-gray-400 dark:hover:bg-gray-600 dark:hover:border-gray-500"
                      >
                        <div className="flex flex-col items-center justify-center pt-5 pb-6">
                          <svg
                            className="w-12 h-12 mb-3 text-gray-400 dark:text-gray-300"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 20 16"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                            />
                          </svg>
                          <p className="mb-2 text-base text-gray-600 dark:text-gray-300 font-semibold">
                            Click to upload
                          </p>
                          <p className="text-xs text-gray-500 dark:text-gray-400">
                            Supported formats: SVG, PNG, JPG, GIF (Max:
                            800x400px)
                          </p>
                          {productImage.length > 0 && (
                            <p className="mt-2 text-sm text-gray-500 dark:text-gray-400">
                              {productImage.length} file(s) selected
                            </p>
                          )}
                        </div>
                        <input
                          id="product_image"
                          type="file"
                          name="product_image"
                          onChange={handleProductFileChange}
                          className="hidden"
                          multiple
                        />
                      </label>
                    </div>
                  </div>

                  <div className="flex items-center space-x-4">
                    <button
                      type="submit"
                      className="text-white bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                    >
                      Create Product
                    </button>
                  </div>
                </form>
                {error && <p className="text-red-500 mt-4">{error}</p>}
                {success && <p className="text-green-500 mt-4">{success}</p>}
              </div>
            </section>
          </div>
        )}
        {showMenu === 3 && (
          <div className="relative overflow-x-auto p-4">
            {orders.length > 0 ? (
              <table
                className="min-w-full border border-gray-300 rounded-lg shadow-lg"
                style={{ position: "relative", left: "20%" }}
              >
                <thead className="bg-gradient-to-r from-blue-500 to-teal-500 text-white">
                  <tr>
                    <th className="border border-gray-300 p-4 text-left font-semibold">
                      Order ID
                    </th>
                    <th className="border border-gray-300 p-4 text-left font-semibold">
                      Created Date
                    </th>
                    <th className="border border-gray-300 p-4 text-left font-semibold">
                      User ID
                    </th>
                    <th className="border border-gray-300 p-4 text-left font-semibold">
                      Final Amount
                    </th>
                    <th className="border border-gray-300 p-4 text-left font-semibold">
                      Shipping Address
                    </th>
                    <th className="border border-gray-300 p-4 text-left font-semibold">
                      Shipping Cost
                    </th>
                    <th className="border border-gray-300 p-4 text-left font-semibold">
                      Payment Method
                    </th>
                    <th className="border border-gray-300 p-4 text-left font-semibold">
                      Payment Status
                    </th>
                    <th className="border border-gray-300 p-4 text-left font-semibold">
                      Delivery Status
                    </th>
                    <th className="border border-gray-300 p-4 text-left font-semibold">
                      Order items
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {orders.map((order) => (
                    <tr
                      key={order.order_id}
                      className="hover:bg-gray-100 transition-colors duration-200"
                    >
                      <td className="border border-gray-300 p-4">
                        {order.order_id}
                      </td>
                      <td className="border border-gray-300 p-4">
                        {new Date(order.created_date).toLocaleString()}
                      </td>
                      <td className="border border-gray-300 p-4">
                        {order.user_id}
                      </td>
                      <td className="border border-gray-300 p-4">
                        ${order.final_amount}
                      </td>
                      <td className="border border-gray-300 p-4">
                        {order.shipping_address}
                      </td>
                      <td className="border border-gray-300 p-4">
                        ${order.shipping_cost}
                      </td>
                      <td className="border border-gray-300 p-4">
                        {order.payment_method}
                      </td>
                      <td className="border border-gray-300 p-4">
                        {order.payment_status}
                        <div className="flex space-x-2 mt-2">
                          {" "}
                          {/* Add margin for spacing */}
                          {["unpaid", "paid", "rejected"].map(
                            (paymentStatus) => (
                              <button
                                key={paymentStatus}
                                className="bg-green-500 text-white px-2 py-1 rounded hover:bg-green-600"
                                onClick={() =>
                                  handleUpdatePaymentStatus(
                                    order.order_id,
                                    paymentStatus
                                  )
                                }
                              >
                                {paymentStatus}
                              </button>
                            )
                          )}
                        </div>
                      </td>
                      <td className="border border-gray-300 p-4">
                        <div className="flex flex-col space-y-2">
                          <span className="font-semibold text-gray-800">
                            {order.order_status}
                          </span>
                          <div className="flex space-x-2">
                            {[
                              "pending",
                              "packing",
                              "delivering",
                              "complete",
                            ].map((OrderStatus) => (
                              <button
                                onClick={() =>
                                  handleUpdateStatus(
                                    order.order_id,
                                    OrderStatus
                                  )
                                }
                                key={OrderStatus}
                                className="bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600 transition duration-200"
                              >
                                {OrderStatus}
                              </button>
                            ))}
                          </div>
                        </div>
                      </td>
                      <td className="border border-gray-300 p-4">
                        {/* Render a nested table for products in this order */}
                        <table className="min-w-full border border-gray-300 mt-2">
                          <thead>
                            <tr className="bg-gray-100">
                              <th className="border border-gray-300 p-2 text-left">
                                Product Name
                              </th>
                              <th className="border border-gray-300 p-2 text-left">
                                Sales Price
                              </th>
                              <th className="border border-gray-300 p-2 text-left">
                                Quantity
                              </th>
                              <th className="border border-gray-300 p-2 text-left">
                                Subtotal Price
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {order.products.map((product) => (
                              <tr
                                key={product.product_id}
                                className="hover:bg-gray-50"
                              >
                                <td className="border border-gray-300 p-2">
                                  {product.product_name}
                                </td>
                                <td className="border border-gray-300 p-2">
                                  ${product.product_price}
                                </td>
                                <td className="border border-gray-300 p-2">
                                  {product.quantity}
                                </td>
                                <td className="border border-gray-300 p-2">
                                  ${product.total_price}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <p className="text-gray-600">No orders found.</p>
            )}
          </div>
        )}
        {showModal && (
          <div className="modal-overlay">
            <div className="modal">
              <div className="modal-content">
                <h2>Edit User</h2>
                {error && <p style={{ color: "red" }}>{error}</p>}
                <form onSubmit={handleSubmitEdit}>
                  <label>
                    Username:
                    <input
                      type="text"
                      name="username"
                      value={formData.username}
                      onChange={handleChange}
                    />
                  </label>
                  <label>
                    Email:
                    <input
                      type="email"
                      name="user_email"
                      value={formData.user_email}
                      onChange={handleChange}
                    />
                  </label>
                  <label>
                    User Type:
                    <input
                      type="text"
                      name="user_type"
                      value={formData.user_type}
                      onChange={handleChange}
                      placeholder="1 = Admin, 2 = Staff, 3 = Partner, 4 = Customer"
                    />
                  </label>
                  <label>
                    Phone Number:
                    <input
                      type="text"
                      name="phone_number"
                      value={formData.phone_number}
                      onChange={handleChange}
                    />
                  </label>
                  <label>
                    Address:
                    <input
                      type="text"
                      name="user_address"
                      value={formData.user_address}
                      onChange={handleChange}
                    />
                  </label>
                  <label>
                    Country:
                    <input
                      type="text"
                      name="user_country"
                      value={formData.user_country}
                      onChange={handleChange}
                    />
                  </label>
                  <label>
                    Postal Code:
                    <input
                      type="text"
                      name="user_postal_code"
                      value={formData.user_postal_code}
                      onChange={handleChange}
                    />
                  </label>
                  <label>
                    Logo Image:
                    <input
                      type="file"
                      name="logo_image"
                      onChange={handleChange}
                    />
                  </label>
                  <div className="modal-buttons">
                    <button type="submit">Save Changes</button>
                    <button type="button" onClick={() => setShowModal(false)}>
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}
        <ImageModal
          images={images}
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
        />
        {showEditModal && (
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex justify-center items-center">
            <div className="bg-white p-8 rounded-lg shadow-lg max-w-lg w-full">
              <h2 className="text-xl font-bold mb-4">Edit Product</h2>
              <form
                onSubmit={async (e) => {
                  e.preventDefault();

                  // Collect product data from the form inputs
                  const updatedProductData = {
                    product_name: e.target.product_name.value,
                    product_description: e.target.product_description.value,
                    product_cost: parseFloat(e.target.product_cost.value),
                    product_price: parseFloat(e.target.product_price.value),
                    available_quantity: parseInt(
                      e.target.available_quantity.value
                    ),
                    product_category: e.target.category.value,
                  };

                  try {
                    // Send updated product data to your server
                    await editProduct(
                      currentProduct.product_id,
                      updatedProductData
                    );
                    setShowEditModal(false); // Close modal after submission
                  } catch (error) {
                    console.error("Error updating product:", error);
                    setError("Failed to update product"); // Optionally set an error state for UI feedback
                  }
                }}
              >
                {/* Input for Product Name */}
                <div className="mb-4">
                  <label
                    className="block text-sm font-bold mb-2"
                    htmlFor="product_name"
                  >
                    Product Name
                  </label>
                  <input
                    type="text"
                    id="product_name"
                    name="product_name"
                    defaultValue={currentProduct?.product_name || ""}
                    className="w-full px-3 py-2 border rounded"
                    required
                  />
                </div>

                {/* Input for Product Description */}
                <div className="mb-4">
                  <label
                    className="block text-sm font-bold mb-2"
                    htmlFor="product_description"
                  >
                    Product Description
                  </label>
                  <input
                    type="text"
                    id="product_description"
                    name="product_description"
                    defaultValue={currentProduct?.product_description || ""}
                    className="w-full px-3 py-2 border rounded"
                    required
                  />
                </div>

                {/* Input for Product Cost */}
                <div className="mb-4">
                  <label
                    className="block text-sm font-bold mb-2"
                    htmlFor="product_cost"
                  >
                    Product Cost
                  </label>
                  <input
                    type="number"
                    step="0.01"
                    id="product_cost"
                    name="product_cost"
                    defaultValue={currentProduct?.product_cost || ""}
                    className="w-full px-3 py-2 border rounded"
                    required
                  />
                </div>

                {/* Input for Product Price */}
                <div className="mb-4">
                  <label
                    className="block text-sm font-bold mb-2"
                    htmlFor="product_price"
                  >
                    Product Price
                  </label>
                  <input
                    type="number"
                    step="0.01"
                    id="product_price"
                    name="product_price"
                    defaultValue={currentProduct?.product_price || ""}
                    className="w-full px-3 py-2 border rounded"
                    required
                  />
                </div>

                {/* Input for Available Quantity */}
                <div className="mb-4">
                  <label
                    className="block text-sm font-bold mb-2"
                    htmlFor="available_quantity"
                  >
                    Available Quantity
                  </label>
                  <input
                    type="number"
                    id="available_quantity"
                    name="available_quantity"
                    defaultValue={currentProduct?.available_quantity || ""}
                    className="w-full px-3 py-2 border rounded"
                    required
                  />
                </div>

                {/* Input for Product Category */}
                <div className="mb-4">
                  <label
                    className="block text-sm font-bold mb-2"
                    htmlFor="category"
                  >
                    Product Category
                  </label>
                  <input
                    type="text"
                    id="category"
                    name="category"
                    defaultValue={currentProduct?.product_category || ""}
                    className="w-full px-3 py-2 border rounded"
                    required
                  />
                </div>

                {/* Buttons for Cancel and Save */}
                <div className="flex justify-end">
                  <button
                    type="button"
                    className="mr-4 px-4 py-2 bg-gray-300 rounded-lg"
                    onClick={() => setShowEditModal(false)}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="px-4 py-2 bg-blue-500 text-white rounded-lg"
                  >
                    Save Changes
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Admin;
