/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Navbar from "./Navbar"; // Import the Navbar component
import "react-toastify/dist/ReactToastify.css";
import "./Login.css"; // Ensure this CSS file contains the necessary styles

function Login() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    user_email: "",
    password: "",
  });

  const [errors, setErrors] = useState({
    user_email: "",
    password: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [apiError, setApiError] = useState("");

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prev) => ({ ...prev, [id]: value }));
  };
  const domainname = "http://www.just-one-price.com:3000";
  const validateForm = () => {
    let isValid = true;
    const newErrors = { user_email: "", password: "" };

    // Validate email
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!formData.user_email || !emailPattern.test(formData.user_email)) {
      newErrors.user_email = "Please enter a valid email.";
      isValid = false;
    }

    // Validate password
    if (!formData.password) {
      newErrors.password = "Password is required.";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setIsSubmitting(true);
    setApiError("");

    try {
      const response = await fetch(`${domainname}/user/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error("Login failed");
      }

      const data = await response.json();

      if (data.message === "Login successful") {
        toast.success("Login successful!");
        setTimeout(() => {
          window.location.reload(); // Auto-refresh after additional 2 seconds
        }, 100);
        localStorage.setItem("user_id", data.user_id);
        localStorage.setItem("user_type", data.user_type);
        // Assuming `data.token` is a string. If not, convert it to string before storing
        localStorage.setItem("token", data.token);

        switch (data.user_type) {
          case 1:
            navigate("/Admin");
            break;
          case 2:
            navigate("/Staff");
            break;
          case 3:
            navigate("/Partner");
            break;
          default:
            navigate("/");
        }
      } else {
        setApiError(
          "Login failed. Please check your credentials and try again."
        );
      }
    } catch (error) {
      console.error("Login failed:", error);
      setApiError("Login failed. Please check your credentials and try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col">
      {/* Include the Navbar component */}
      <Navbar />

      <div className="flex flex-col justify-center py-12 sm:px-6 lg:px-8 flex-grow">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Sign in to your account
          </h2>
          <p className="mt-2 text-center text-sm text-gray-600 max-w">
            Or{" "}
            <a
              href="/Register"
              className="font-medium text-blue-600 hover:text-blue-500"
            >
              create an account
            </a>
          </p>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <form className="space-y-6" onSubmit={handleSubmit} noValidate>
              {apiError && (
                <p className="text-red-500 text-sm mt-2">{apiError}</p>
              )}
              <div>
                <label
                  htmlFor="user_email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email address
                </label>
                <div className="mt-1">
                  <input
                    id="user_email"
                    name="user_email"
                    type="email"
                    autoComplete="email"
                    required
                    value={formData.user_email}
                    onChange={handleChange}
                    className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    placeholder="Enter your email address"
                  />
                  {errors.user_email && (
                    <p className="text-red-500 text-sm mt-2">
                      {errors.user_email}
                    </p>
                  )}
                </div>
              </div>

              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  Password
                </label>
                <div className="mt-1">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    required
                    value={formData.password}
                    onChange={handleChange}
                    className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    placeholder="Enter your password"
                  />
                  {errors.password && (
                    <p className="text-red-500 text-sm mt-2">
                      {errors.password}
                    </p>
                  )}
                </div>
              </div>

              <div className="flex items-center justify-between">
                <div className="text-sm">
                  <a
                    href="#"
                    className="font-medium text-blue-600 hover:text-blue-500"
                  >
                    Forgot your password?
                  </a>
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  {isSubmitting ? "Signing in..." : "Sign in"}
                </button>
              </div>
            </form>

            <div className="mt-6">
              <div className="relative">
                <div className="absolute inset-0 flex items-center">
                  <div className="w-full border-t border-gray-300"></div>
                </div>
                <div className="flex flex-col items-center space-y-4">
                  {/* First Section */}
                  <div className="relative flex justify-center text-sm">
                    <span className="px-2 py-1 bg-gray-100 text-gray-500 rounded-md">
                      Or continue with
                    </span>
                  </div>

                  {/* Second Section */}
                  <div className="relative flex justify-center text-sm">
                    <span className="px-4 py-2 bg-gradient-to-r from-blue-500 to-purple-500 text-white font-semibold rounded-full shadow-lg">
                      Stay Tuned
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
