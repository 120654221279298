// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.products {
  width: 100%;
  border-collapse: collapse;
}

.product-cell {
  padding: 15px;
  text-align: center;
}

.product-name {
  display: block;
  font-size: 1.2em;
  margin: 5px 0;
}
.aspect-w-1.aspect-h-1 {
  aspect-ratio: 1 / 1;
}

.product-code {
  font-size: 1em;
  color: #666;
}

.tablecellimage {
  width: 150px; /* Fixed width */
  height: 150px; /* Fixed height */
  object-fit: cover; /* Ensures the image covers the fixed size */
  border: 1px solid #ddd; /* Optional: Adds a border around the image */
}
.image-size {
  width: 8rem; /* Adjust to your preferred size */
  height: 8rem; /* Adjust to your preferred size */
  object-fit: cover; /* Maintain aspect ratio and cover the area */
}
.main-image {
  max-width: 100%; /* Keep it responsive */
  max-height: 12rem; /* Example fixed height */
  object-fit: cover; /* Maintain aspect ratio */
}
`, "",{"version":3,"sources":["webpack://./src/customer/Customer.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,gBAAgB;EAChB,aAAa;AACf;AACA;EACE,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,WAAW;AACb;;AAEA;EACE,YAAY,EAAE,gBAAgB;EAC9B,aAAa,EAAE,iBAAiB;EAChC,iBAAiB,EAAE,4CAA4C;EAC/D,sBAAsB,EAAE,6CAA6C;AACvE;AACA;EACE,WAAW,EAAE,kCAAkC;EAC/C,YAAY,EAAE,kCAAkC;EAChD,iBAAiB,EAAE,6CAA6C;AAClE;AACA;EACE,eAAe,EAAE,uBAAuB;EACxC,iBAAiB,EAAE,yBAAyB;EAC5C,iBAAiB,EAAE,0BAA0B;AAC/C","sourcesContent":[".products {\r\n  width: 100%;\r\n  border-collapse: collapse;\r\n}\r\n\r\n.product-cell {\r\n  padding: 15px;\r\n  text-align: center;\r\n}\r\n\r\n.product-name {\r\n  display: block;\r\n  font-size: 1.2em;\r\n  margin: 5px 0;\r\n}\r\n.aspect-w-1.aspect-h-1 {\r\n  aspect-ratio: 1 / 1;\r\n}\r\n\r\n.product-code {\r\n  font-size: 1em;\r\n  color: #666;\r\n}\r\n\r\n.tablecellimage {\r\n  width: 150px; /* Fixed width */\r\n  height: 150px; /* Fixed height */\r\n  object-fit: cover; /* Ensures the image covers the fixed size */\r\n  border: 1px solid #ddd; /* Optional: Adds a border around the image */\r\n}\r\n.image-size {\r\n  width: 8rem; /* Adjust to your preferred size */\r\n  height: 8rem; /* Adjust to your preferred size */\r\n  object-fit: cover; /* Maintain aspect ratio and cover the area */\r\n}\r\n.main-image {\r\n  max-width: 100%; /* Keep it responsive */\r\n  max-height: 12rem; /* Example fixed height */\r\n  object-fit: cover; /* Maintain aspect ratio */\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
