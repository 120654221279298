import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./NavbarStyles.css";
import { FaShoppingCart } from "react-icons/fa";
import { GiLion } from "react-icons/gi";

import "./CartIcon.css";

const Navbar = () => {
  const [clicked, setClicked] = useState(false);
  const userId = localStorage.getItem("user_id");
  const token = localStorage.getItem("token");
  const domainname = "http://www.just-one-price.com:3000";
  const handleClick = () => {
    setClicked(!clicked);
  };

  const handleLogout = async () => {
    try {
      await fetch(`${domainname}/user/logout/${userId}/${token}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      });
      toast.success("Logged out successfully!");
    } catch (error) {
      console.error("Logout failed:", error);
    } finally {
      // Remove user data from localStorage
      localStorage.removeItem("user_id");
      localStorage.removeItem("token");
      localStorage.removeItem("user_type"); // Clear user type

      // Reload the page after 2 seconds to reflect changes
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
  };

  

  const MenuItems = [
    { title: "Home", url: "/", cName: "nav-links home" },
    { title: "Order", url: "/Order", cName: "nav-links wholesale" },
    { title: "Profile", url: "/Profile", cName: "nav-links profile" },
  
  ];

  const userType = parseInt(localStorage.getItem("user_type"), 10);

  return (
    <nav className="NavbarItems">
      <h1 className="navbar-logo">
        JuztWholesale
        <GiLion
          style={{
            fontSize: "2rem",
            marginLeft: "0.5rem",
            marginBottom: "0.5rem",
          }}
        />
      </h1>
      
      <div className="menu-icons" onClick={handleClick}>
        <i className={clicked ? "fas fa-times" : "fas fa-bars"}></i>
      </div>

      <ul className={clicked ? "nav-menu active" : "nav-menu"}>
        {MenuItems.map((item, index) => (
          <li key={index}>
            <Link className={item.cName} to={item.url}>
              {item.title}
            </Link>
          </li>
        ))}

        <div className="signup-container">
          {token ? (
            <>
              <Link to="/ShoppingCart" className="cart-icon">
                <FaShoppingCart size={25} className="cart-icon-logo" />
                <span className="cart-text">Shopping Cart</span>
              </Link>

              {userType === 1 && (
                <Link to="/admin" className="nav-button admin-button mb-3">
                  Admin
                </Link>
              )}
              {userType === 2 && (
                <Link to="/staff" className="nav-button staff-button">
                  Staff
                </Link>
              )}
              {userType === 3 && (
                <Link to="/partner" className="nav-button partner-button">
                  Partner
                </Link>
              )}

              <button
                className="nav-button logout-button mb-3"
                onClick={handleLogout}
              >
                Logout
              </button>
            </>
          ) : (
            <Link to="/Login" className="nav-button login-button mb-2">
              Sign Up & Login
            </Link>
          )}
        </div>
      </ul>

      <ToastContainer autoClose={1500} />
    </nav>
  );
};

export default Navbar;
