import React from "react";
import { Navigate, useLocation } from "react-router-dom";

// Higher-order component for role-based access control
const ProtectedRoute = ({ children, allowedRoles }) => {
  const location = useLocation();
  const user_id = localStorage.getItem("user_id"); // Retrieve user info from local storage
  const user_type = localStorage.getItem("user_type"); // Retrieve user info from local storage

  if (!user_id) {
    // If the user is not logged in, redirect to the login page
    return <Navigate to="/login" state={{ from: location }} />;
  }

  // Ensure user_type is defined before checking its inclusion in allowedRoles
  if (!user_type || !allowedRoles.includes(parseInt(user_type, 10))) {
    // If not allowed, redirect to a not authorized page or homepage
    return <Navigate to="/not-authorized" state={{ from: location }} />;
  }

  return children;
};

export default ProtectedRoute;
