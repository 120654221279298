import React, { useEffect, useState } from "react";
import "./Order.css"; // Import your CSS file for styling
import { useNavigate } from "react-router-dom"; // Import the useNavigate hook
const Order = () => {
  const [orders, setOrders] = useState([]); // State to hold orders
  const [loading, setLoading] = useState(true); // State for loading indicator
  const domainname = "http://www.just-one-price.com:3000"; // Replace with your actual domain
  const navigate = useNavigate(); // Initialize navigate
  const fetchOrders = async () => {
    let user_id = localStorage.getItem("user_id");
    let token = localStorage.getItem("token");

    try {
      const response = await fetch(
        `${domainname}/order/users/orders`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            user_id: user_id,
            token: token
          })
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();

      // Check if data is an array
      if (Array.isArray(data)) {
        // Sort orders by order_id on initial load
        setOrders(data.sort((a, b) => a.order_id - b.order_id));
      } else {
        console.error("Expected an array but received:", data);
        setOrders([]); // or some other default state
      }
    } catch (error) {
      console.error("Error fetching orders:", error);
      setOrders([]); // or some other default state
    } finally {
      setLoading(false); // Stop loading after fetch completes
    }
  };

  useEffect(() => {
    fetchOrders();
  }, []);

  return (
    <div className="order-container">
      <h1>Your Orders</h1>
      <button onClick={() => navigate("/")} className="go-main-button">
        Go to Main Page
      </button>
      {loading ? (
        <p>Loading your orders...</p>
      ) : orders.length > 0 ? (
        <table className="orders-table">
          <thead>
            <tr>
              <th>Order ID</th>
              <th>Created Date</th>
              <th>Payment Method</th>
              <th>Payment Status</th>
              <th>Order Status</th>
              <th>Product</th>
              <th>Total Price</th>
            </tr>
          </thead>
          <tbody>
            {orders.map((order) => (
              <tr key={order.order_id}>
                <td>{order.order_id}</td>
                <td>{new Date(order.created_date).toLocaleDateString()}</td>
                <td>{order.payment_method}</td>
                <td>{order.payment_status}</td>
                <td>{order.order_status}</td>
                <td>
                  <ul className="product-list">
                    {order.products.map((product) => (
                      <li key={product.product_id} className="product-item">
                        <div className="product-info">
                          <span className="product-code">
                            Product Code: {product.product_code}
                          </span>
                          <span className="product-name">
                            Product Name:{product.product_name}{" "}
                            <div>Quantity:({product.quantity})</div>
                          </span>
                          <span className="product-price">
                            Subtotal: ${product.total_price.toFixed(2)}
                          </span>
                          <img
                            alt={product.product_name}
                            src={`${domainname}/product/products/images/first-image/default/${product.product_code}`}
                            className="product-image"
                          />{" "}
                        </div>
                      </li>
                    ))}
                  </ul>
                </td>
                <td>
                  <div>
                    $
                    {order.products
                      .reduce(
                        (total, product) => total + product.total_price,
                        order.shipping_cost || 0
                      )
                      .toFixed(2)}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No orders found.</p>
      )}
    </div>
  );
};

export default Order;
