/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import paynow from "../assets/paynow.png";
import "./CheckoutStyles.css"; // Import the CSS file

const domainname = "http://www.just-one-price.com:3000";
let user_id = localStorage.getItem("user_id");
let token = localStorage.getItem("token");

function Checkout() {
  const [deliveryCost] = useState(2.9);
  const MAX_LENGTH = 5; // Set your desired maximum length here

  const [isLoading, setIsLoading] = useState(true);
  const [cartItems, setCartItems] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [formData, setFormData] = useState({
    address: "",
    postalCode: "",
    shipping_address: "",
    payment_method: "PayNow", // Set default payment method
  });
  const [user, setUser] = useState(null); // State for user data
  const [error, setError] = useState(null); // State for error handling
  const [loading, setLoading] = useState(true); // Loading state for user data
  const [showQRCode, setShowQRCode] = useState(false);
  const navigate = useNavigate();
  const handleOk = () => {
    // Navigate to the home page when OK button is clicked
    navigate("/");
  };
  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const fetchSelectedCartItems = async () => {
    try {
      const response = await fetch(`${domainname}/product/ShoppingCart/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user_id: user_id,
          token: token,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to fetch cart items");
      }
      const result = await response.json();
      await new Promise((resolve) => setTimeout(resolve, 200)); // 2 seconds delay
      setCartItems(result.data);


      //const result = localStorage.getItem("shopping_cart");
      //alert(result[0].product_name)

      //setCartItems(result);
    } catch (error) {
      console.error("Error fetching cart items:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchUserData = async () => {
    try {
      const user_id = localStorage.getItem("user_id");
      const token = localStorage.getItem("token");
      const userId = localStorage.getItem("user_id");
      if (!user_id || !token) {
        throw new Error("User data or token is missing");
      }

      const response = await fetch(
        `${domainname}/user/users/${user_id}/${encodeURIComponent(
          token
        )}/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch user data");
      }

      const data = await response.json();
      setUser(data);
      setFormData((prevData) => ({
        ...prevData,
        address: data.user_address || "",
        postalCode: data.user_postal_code || "", // Added postal code
      }));
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSelectedCartItems();
    fetchUserData();
  }, []);

  const getTotalPrice = () => {
    return cartItems.reduce((total, item) => {
      return total + item.price * item.quantity; // Assuming each item has price and quantity
    }, 0);
  };

  const renderQRCode = () => {
    if (showQRCode) {
      return (
        <div className="qr-code-wrapper">
          <h5>
            Scan the QR Code and Pay
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6 inline ml-2"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M12 4v1m6 11h2m-6 0h-2v4m0-11v3m0 0h.01M12 12h4.01M16 20h4M4 12h4m12 0h.01M5 8h2a1 1 0 001-1V5a1 1 0 00-1-1H5a1 1 0 00-1 1v2a1 1 0 001 1zm12 0h2a1 1 0 001-1V5a1 1 0 00-1-1h-2a1 1 0 00-1 1v2a1 1 0 001 1zM5 20h2a1 1 0 001-1v-2a1 1 0 00-1-1H5a1 1 0 00-1 1v2a1 1 0 001 1z"
              />
            </svg>
          </h5>
          <div className="qr-code-container">
            <img
              src={paynow}
              alt="PayNow QR Code"
              style={{ width: "40%", height: "50%", objectFit: "contain" }}
            />
          </div>
        </div>
      );
    }
    return null;
  };

  const handleSubmit = async () => {
    const confirmation = window.confirm(
      "Are you sure you want to confirm your purchase?"
    );

    if (confirmation) {
      // Combine address and postalCode into shipping_address
      const shippingAddress = `${formData.address} - ${formData.postalCode}`;

      const response = await fetch(`${domainname}/order/orders/addOrder`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user_id: user_id,
          token: token,
          shipping_address: shippingAddress,
          payment_method: "PayNow",
          order_products: cartItems,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to fetch cart items");
      }

      setShowQRCode(true); // Show the QR code after confirmation
      setSubmitted(true);
    }
    // You can also add additional order processing logic here
    // Send formData, including shipping_address and payment_method to your API
  };

  const truncateName = (name) => {
    return name.length > MAX_LENGTH ? name.slice(0, MAX_LENGTH) : name;
  };

  const calculateTotalPrice = () => {
    return cartItems
      .reduce((total, item) => {
        return total + item.price * item.quantity;
      }, 0)
      .toFixed(2);
  };
  const showFullNameToast = (name) => {
    toast.info(name, {
      position: "top-right",
      autoClose: 3000, // Duration in milliseconds
      hideProgressBar: true,
      closeOnClick: true,
      draggable: true,
    });
  };

  //   const click1 = () => {
  //     alert("hello");
  //     alert("cartItem:" + cartItems[0].product_name);
  //     alert("cartItem:" + cartItems[0].quantity);

  //     alert("cartItem:" + cartItems[1].product_name);
  //     alert("cartItem:" + cartItems[1].quantity);
  //   };
  return (
    <div className="form-container ">
      <ToastContainer />

      <div className="navigation-links" style={{ marginBottom: "20px" }}>
        <Link to="/ShoppingCart">
          <button className="nav-button">Back to Cart</button>
        </Link>
        <Link to="/">
          <button className="nav-button">Back to Home</button>
        </Link>
        {/* <button className="nav-button" onClick={() => click1()}>
          Click
        </button> */}
      </div>
      <h3 className="checkout-header">Checkout</h3>
     
      {/* User Details Table */}
      <div className="mt-6 flex flex-col lg:flex-row lg:space-x-6">
        <div className="flex-1">
          {cartItems.length > 0 ? (
            cartItems.map((item) => (
              <div
                key={item.product_id}
                className="bg-gray-50 border border-gray-200 rounded-lg shadow-sm p-4 mb-4 hover:shadow-lg transition-shadow duration-200"
              >
                <div className="flex justify-between items-center">
                  <div>
                    <h3 className="text-lg font-semibold text-gray-900">
                      Product:{truncateName(item.product_name)}
                      {item.product_name.length > MAX_LENGTH && (
                        <span
                          className="text-blue-600 cursor-pointer"
                          onClick={() => showFullNameToast(item.product_name)}
                        >
                          ...
                        </span>
                      )}
                    </h3>
                  </div>

                  <div className="flex items-center space-x-3">
                    <p className="text-gray-600">Quantity:{item.quantity}</p>
                  </div>
                  <p className="text-gray-600">
                    Subtotal: ${item.price * item.quantity}
                  </p>
                </div>
              </div>
            ))
          ) : (
            <div className="text-center text-gray-600">
              {isLoading ? (
                <p>Loading, please wait...</p> // Loading message
              ) : cartItems.length === 0 ? (
                <p>
                  Your shopping cart is empty. Or if you logged in, just refresh
                  the page.
                  <div>
                    <Link
                      to="/"
                      style={{
                        color: "#007bff",
                        textDecoration: "underline",
                      }} // Inline styles
                      onMouseEnter={(e) => (e.target.style.color = "#0056b3")} // Darker shade on hover
                      onMouseLeave={(e) => (e.target.style.color = "#007bff")} // Return to original color
                    >
                      Go Home Page
                    </Link>
                  </div>
                </p>
              ) : (
                // Render cart items here if available
                <div>Your cart items will be displayed here.</div>
              )}
            </div>
          )}
        </div>
      </div>
      {/* User Details Table */}
      <table style={{ width: "100%", marginBottom: "20px" }}>
        <tbody>
          <tr>
            <td className="table-cell">
              <label className="label">
                Delivery Address
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="size-6 inline ml-1"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                  />
                </svg>
              </label>
              <input
                type="text"
                name="address"
                value={formData.address}
                onChange={handleInputChange}
                placeholder="Enter your address"
                className="input"
              />
            </td>
          </tr>
          <tr>
            <td className="table-cell">
              <label className="label">
                Postal Code
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6 inline ml-1"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M11 4a2 2 0 114 0v1a1 1 0 001 1h3a1 1 0 011 1v3a1 1 0 01-1 1h-1a2 2 0 100 4h1a1 1 0 011 1v3a1 1 0 01-1 1h-3a1 1 0 01-1-1v-1a2 2 0 10-4 0v1a1 1 0 01-1 1H7a1 1 0 01-1-1v-3a1 1 0 00-1-1H4a2 2 0 110-4h1a1 1 0 001-1V7a1 1 0 011-1h3a1 1 0 001-1V4z"
                  />
                </svg>
              </label>
              <input
                type="text"
                name="postalCode"
                value={formData.postalCode}
                onChange={handleInputChange}
                placeholder="Enter your postal code"
                className="input "
              />
            </td>
          </tr>
        </tbody>
      </table>

      {renderQRCode()}
      {submitted ? (
        <button type="button" className="ok-button" onClick={handleOk}>
          OK
        </button>
      ) : (
        <button type="button" className="submit-button" onClick={handleSubmit}>
          Submit Order and Pay
        </button>
      )}
    </div>
  );
}

export default Checkout;
