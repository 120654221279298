import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./NavbarStyles.css";
import { FaShoppingCart } from "react-icons/fa";
import { GiLion } from "react-icons/gi";

import "./CartIcon.css";

const Navbar = () => {
  const [clicked, setClicked] = useState(false);
  const handleClick = () => {
    setClicked(!clicked);
  };

  const handleLogout = async () => {
    const user_id = localStorage.getItem("user_id");
    const token = localStorage.getItem("token");

    try {
      await fetch(`http://www.just-one-price.com:3000/user/logout/${user_id}/${token}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      });
    } catch (error) {
      console.error("Logout failed:", error);
    }

    // Remove the token and user from localStorage
    localStorage.removeItem("user_id");
    localStorage.removeItem("token");

    // Show a toast notification for successful logout
    toast.success("Logged out successfully!");

    // Redirect to homepage
   
    window.location.reload();
  };

  const MenuItems = [
    {
      title: "Home",
      url: "/",
      cName: "nav-links home",
    },
    {
      title: "Order",
      url: "/Order",
      cName: "nav-links wholesale",
    },
    {
      title: "Profile",
      url: "/Profile",
      cName: "nav-links profile",
    },
  ];
  const isLoggedIn = !!localStorage.getItem("token");
  const user_id = localStorage.getItem("user_id");
  const token = localStorage.getItem("token");
  const userType = parseInt(localStorage.getItem("user_type"), 10);

  return (
    <nav className="NavbarItems">
      <h1 className="navbar-logo">
        JuztOnePrice
        <GiLion
          style={{
            fontSize: "2rem",
            marginLeft: "0.5rem",
            marginBottom: "0.5rem",
          }}
        />
      </h1>

      <div className="menu-icons" onClick={handleClick}>
        <i className={clicked ? "fas fa-times" : "fas fa-bars"}></i>
      </div>

      <ul className={clicked ? "nav-menu active" : "nav-menu"}>
        {MenuItems.map(
          (item, index) =>
            (item.title !== "Profile" || isLoggedIn) && (
              <li key={index}>
                <Link className={item.cName} to={item.url}>
                  {item.title}
                </Link>
              </li>
            )
        )}

        <div className="signup-container">
          {isLoggedIn ? (
            <>
              <Link to="/ShoppingCart" className="cart-icon">
                <FaShoppingCart size={25} className="cart-icon-logo" />

                <span className="cart-text">Shopping Cart</span>
              </Link>

              {userType === 1 && (
                <Link to="/admin" className="nav-button admin-button">
                  Admin
                </Link>
              )}
              {userType === 2 && (
                <Link to="/staff" className="nav-button staff-button">
                  Staff
                </Link>
              )}
              {userType === 3 && (
                <Link to="/partner" className="nav-button partner-button">
                  Partner
                </Link>
              )}
              {user_id &&
                token && ( // Conditionally render logout button
                  <button
                    className="nav-button logout-button"
                    onClick={handleLogout}
                  >
                    Logout
                  </button>
                )}
            </>
          ) : (
            <Link to="/Login" className="nav-button login-button">
              Sign Up & Login
            </Link>
          )}
        </div>
      </ul>

      <ToastContainer autoClose={1500} />
    </nav>
  );
};

export default Navbar;
