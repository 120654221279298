import React, { useEffect, useState } from "react";
import "./Profile.css";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function Profile() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState({});
  // eslint-disable-next-line
  const [usernameError, setUsernameError] = useState("");
  const [updated, setUpdated] = useState(false); // Track if profile was updated
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const user_id = localStorage.getItem("user_id");
        const token = localStorage.getItem("token");
        const userId = localStorage.getItem("user_id");
        if (!user_id || !token) {
          throw new Error("User data or token is missing");
        }

        const response = await fetch(
          "http://www.just-one-price.com:3000/user/users/" +
            user_id +
            "/" +
            encodeURIComponent(token) +
            "/" +
            userId,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch user data");
        }

        const data = await response.json();
        setUser(data);
        setFormData({
          username: data.username || "",
          phone_number: data.phone_number || "",
          user_address: data.user_address || "",
          user_country: data.user_country || "",
          user_postal_code: data.user_postal_code || "", // Added postal code
        });
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, [updated]); // Re-fetch when updated changes

  const handleBackClick = () => {
    navigate("/");
  };

  const handleEditToggle = () => {
    setIsEditing((prev) => !prev);
    if (!isEditing) {
      setUsernameError(""); // Reset username error only when entering edit mode
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setUsernameError(""); // Reset error message

    try {
      // Check if the username already exists

      const user_id = localStorage.getItem("user_id");
      const storedToken = localStorage.getItem("token");

      const updateResponse = await fetch(
        `http://www.just-one-price.com:3000/user/update/${user_id}/${encodeURIComponent(
          storedToken
        )}/${user_id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${encodeURIComponent(storedToken)}`,
          },
          body: JSON.stringify(formData),
        }
      );

      if (!updateResponse.ok) {
        const errorMessage = await updateResponse.text();

        // Check for a specific error related to username duplication
        if (
          errorMessage.includes(
            "duplicate key value violates unique constraint"
          )
        ) {
          alert(
            "This username has been used. Please choose another one."
          );
        } else {
          toast.error(errorMessage || "Failed to update profile");
        }
        return; // Prevent further execution and stop navigation
      }

      setUpdated(true); // Trigger re-fetch
      alert("Profile updated successfully!");
    } catch (err) {
      setError(err.message);
      toast.error(err.message);
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!user) return <div>No user found</div>;

  const userTypeMap = {
    1: "Admin",
    2: "Staff",
    3: "Partner",
    4: "Customer",
  };

  return (
    <section className="profile-section">
      <button className="back-button" onClick={handleBackClick}>
        Back to Customer Page
      </button>
      <div className="profile-container">
        <div className="profile-header">
          <img
            src={user.profile_image || "https://via.placeholder.com/150"}
            alt="Profile"
            className="profile-avatar"
          />
          <h2 className="profile-name">
            {user.username || "No username provided"}
          </h2>
          <p className="profile-role">
            {userTypeMap[user.user_type] || "Unknown User Type"}
          </p>
          <button
            onClick={handleEditToggle}
            className={`edit-button ${isEditing ? "editing" : ""}`}
            aria-label={isEditing ? "Cancel editing" : "Edit profile"}
          >
            <i className={isEditing ? "fas fa-times" : "fas fa-pencil-alt"}></i>
            {isEditing ? " Cancel" : " Edit Profile"}
          </button>
        </div>
        <form className="profile-info" onSubmit={handleSubmit}>
          <h3 className="info-title">Information</h3>
          <div className="info-item">
            <label>Username</label>
            <input
              type="text"
              name="username"
              value={formData.username}
              onChange={handleChange}
              disabled={!isEditing}
            />
       
          </div>
          <div className="info-item">
            <label>Phone</label>
            <input
              type="text"
              name="phone_number"
              value={formData.phone_number}
              onChange={handleChange}
              disabled={!isEditing}
            />
          </div>
          <div className="info-item">
            <label>Address</label>
            <input
              type="text"
              name="user_address"
              value={formData.user_address}
              onChange={handleChange}
              disabled={!isEditing}
            />
          </div>
          <div className="info-item">
            <label>Country</label>
            <input
              type="text"
              name="user_country"
              value={formData.user_country}
              onChange={handleChange}
              disabled={!isEditing}
            />
          </div>
          <div className="info-item">
            <label>Postal Code</label>
            <input
              type="text"
              name="user_postal_code"
              value={formData.user_postal_code}
              onChange={handleChange}
              disabled={!isEditing}
            />
          </div>
          {isEditing && (
            <button
              type="submit"
              className="save-button ml-12"
              aria-label="Save changes"
            >
              <i className="fas fa-save"></i> {/* Save icon */}
              Save Changes
            </button>
          )}
        </form>
      </div>
    </section>
  );
}

export default Profile;
