import React from "react";
import { Route, Routes } from "react-router-dom";

import "./App.css";
import Customer from "./customer/Customer";
import Admin from "./admin/Admin";
import Partner from "./partner/Partner";
import Staff from "./staff/Staff";
import Login from "./components/Login";
import Profile from "./customer/Profile";
import Register from "./components/Register";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ProtectedRoute from "./components/ProtectedRoute";
import Checkout from "./components/Checkout";
import ShoppingCart from "./components/ShoppingCart";
import Order from "./customer/Order";

function App() {
  return (
    <div className="App">
      {" "}
      <ToastContainer />
      <Routes>
        <Route path="/" element={<Customer />} />
        <Route path="/:code" element={<Customer />} />
     
        <Route
          path="/Admin"
          element={
            <ProtectedRoute allowedRoles={[1]}>
              <Admin />
            </ProtectedRoute>
          }
        />
        <Route
          path="/Partner"
          element={
            <ProtectedRoute allowedRoles={[3]}>
              <Partner />
            </ProtectedRoute>
          }
        />
        <Route
          path="/Staff"
          element={
            <ProtectedRoute allowedRoles={[2]}>
              <Staff />
            </ProtectedRoute>
          }
        />
        <Route path="/Profile" element={<Profile />} />
        <Route path="/Login" element={<Login />} /> {/* Route for Login page */}
        <Route path="/Register" element={<Register />} />{" "}
        <Route path="/Checkout" element={<Checkout />} />{" "}
        <Route path="/ShoppingCart" element={<ShoppingCart />} />{" "}
        <Route path="/Order" element={<Order />} />
       
      </Routes>
    </div>
  );
}

export default App;
