import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Link } from "react-router-dom"; // Import ToastContainer and toast
import "react-toastify/dist/ReactToastify.css"; // Import the CSS for the toast notifications
import { useNavigate } from "react-router-dom";
const domainname = "http://www.just-one-price.com:3000";
let user_id = localStorage.getItem("user_id");
let token = localStorage.getItem("token");

function ShoppingCart() {
  const [cartItems, setCartItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // Loading state
  const navigate = useNavigate();
  const handleCheckoutClick = () => {
    localStorage.setItem("shopping_cart", cartItems);
    navigate("/checkout"); // This will navigate to the checkout page
  };
  useEffect(() => {
    // Fetch cart items when the component mounts
    fetchCartItems();

    // Set a timeout to auto-refresh after 4 seconds if still loading
    const timer = setTimeout(() => {
      if (isLoading) {
        setTimeout(() => {
          window.location.reload(); // Auto-refresh after additional 2 seconds
        }, 2000); // 2 seconds delay before refresh
      }
    }, 4000); // Initial 4 seconds

    // Clean up the timer
    return () => clearTimeout(timer);
  }, [isLoading]);
  const fetchCartItems = async () => {
    try {
      const response = await fetch(`${domainname}/product/ShoppingCart/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user_id: user_id,
          token: token,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to fetch cart items");
      }
      const result = await response.json();
      await new Promise((resolve) => setTimeout(resolve, 200)); // 2 seconds delay
      setCartItems(result.data || []);
      setIsLoading(false);
   
    } catch (error) {
      console.error("Error fetching cart items:", error);
      setCartItems([]);
      setIsLoading(false); // Stop loading even if there's an error
    }
  };
  const MAX_LENGTH = 5; // Set your desired maximum length here

  const truncateName = (name) => {
    return name.length > MAX_LENGTH ? name.slice(0, MAX_LENGTH) : name;
  };

  const calculateTotalPrice = () => {
    return cartItems
      .reduce((total, item) => {
        return total + item.price * item.quantity;
      }, 0)
      .toFixed(2);
  };

  const increaseQuantity = async (product_id, price, discount) => {
    await updateShoppingCart(product_id, price, 1, discount);
    setCartItems((prevItems) =>
      prevItems.map((item) =>
        item.product_id === product_id
          ? { ...item, quantity: item.quantity + 1 }
          : item
      )
    );
  };

  const decreaseQuantity = async (product_id, price, discount, quantity) => {
    if (quantity > 1) {
      await updateShoppingCart(product_id, price, -1, discount);
      setCartItems((prevItems) =>
        prevItems.map((item) =>
          item.product_id === product_id
            ? { ...item, quantity: item.quantity - 1 }
            : item
        )
      );
    } else {
      toast.warn("Quantity cannot go below 1", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        draggable: true,
      });
    }
  };
  const removeItemFromCart = async (product_id) => {
    // Implement your remove item logic here (similar to adding item)
    const response = await fetch(
      `${domainname}/product/removeFromCart/${user_id}/${encodeURIComponent(
        token
      )}`,
      {
        method: "DELETE", // Assuming you set up a DELETE route
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user_id,
          token,
          product_id,
        }),
      }
    );

    if (response.ok) {
      setCartItems((prevItems) =>
        prevItems.filter((item) => item.product_id !== product_id)
      );
      toast.success("Item removed from cart successfully!", {
        style: {
          position: "fixed",
          top: "20px", // Adjust this value for vertical spacing
          left: "50%",
          marginLeft: "-150px", // Adjust this value based on toast width
          backgroundColor: "#fff", // Background color
          border: "1px solid #ccc", // Border color
          borderRadius: "8px", // Rounded corners
          padding: "16px", // Padding inside the toast
          zIndex: 9999, // Ensure it appears above other content
          boxShadow: "0 2px 10px rgba(0, 0, 0, 0.2)", // Shadow for a better effect
        },
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        draggable: true,
      });
      // Show success notification
    
    } else {
      console.error("Failed to remove item from cart");
      toast.error("Error removing item from cart");
    }
  };
  const showFullNameToast = (name) => {
    toast.info(name, {
      position: "top-right",
      autoClose: 3000, // Duration in milliseconds
      hideProgressBar: true,
      closeOnClick: true,
      draggable: true,
    });
  };
  const updateShoppingCart = async (product_id, price, quantity, discount) => {
    const response = await fetch(`${domainname}/product/addToShoppingCart`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user_id: user_id,
        token: token,
        product_id: product_id,
        price: price,
        quantity: quantity,
        discount: discount,
      }),
    });

    if (response.ok) {
      // Handle successful addition (e.g., show a success message)
      console.log("Item added to cart successfully!");
    } else {
      // Handle errors (e.g., show an error message)
      console.error("Failed to add item to cart");
    }
  };

  return (
    <div className="bg-gray-100 min-h-screen py-8">
      <ToastContainer /> {/* Add ToastContainer to your component */}
      <section className="max-w-screen-xl mx-auto p-6 bg-white rounded-lg shadow-md">
        <h2 className="text-2xl font-bold text-gray-800">Shopping Cart</h2>

        <div className="mt-6 flex flex-col lg:flex-row lg:space-x-6">
          <div className="flex-1">
            {cartItems.length > 0 ? (
              cartItems.map((item) => (
                <div
                  key={item.product_id}
                  className="bg-gray-50 border border-gray-200 rounded-lg shadow-sm p-4 mb-4 hover:shadow-lg transition-shadow duration-200"
                >
                  <div className="flex justify-between items-center">
                    <div>
                      <h3 className="text-lg font-semibold text-gray-900">
                        {truncateName(item.product_name)}
                        {item.product_name.length > MAX_LENGTH && (
                          <span
                            className="text-blue-600 cursor-pointer"
                            onClick={() => showFullNameToast(item.product_name)}
                          >
                            ...
                          </span>
                        )}
                      </h3>
                      <p className="text-gray-600">${item.price}/unit</p>
                    </div>

                    <div className="flex items-center space-x-3">
                      <button
                        onClick={() =>
                          decreaseQuantity(
                            item.product_id,
                            item.price,
                            item.discount,
                            item.quantity // Pass the current quantity
                          )
                        }
                        className="bg-blue-500 text-white rounded-md px-2 py-1 hover:bg-blue-600 transition-colors"
                      >
                        -
                      </button>
                      <input
                        type="text"
                        className="w-20 text-center border border-gray-300 rounded-md bg-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        value={item.quantity}
                        readOnly
                      />
                      <button
                        onClick={() =>
                          increaseQuantity(
                            item.product_id,
                            item.price,
                            item.discount
                          )
                        }
                        className="bg-blue-500 text-white rounded-md px-2 py-1 hover:bg-blue-600 transition-colors"
                      >
                        +
                      </button>
                    </div>
                    <p className="text-gray-600">
                      Subtotal: ${(item.price * item.quantity).toFixed(2)}
                    </p>
                    <button
                      className="text-red-600 hover:text-red-800 transition-colors"
                      onClick={() => removeItemFromCart(item.product_id)}
                    >
                      Remove
                    </button>
                  </div>
                </div>
              ))
            ) : (
              <div className="text-center text-gray-600">
                {isLoading ? (
                  <p>Loading, please wait...</p> // Loading message
                ) : cartItems && cartItems.length === 0 ? (
                  <p>
                    Your shopping cart is empty. Or if you logged in, just
                    refresh the page.
                    <div>
                      <Link
                        to="/"
                        style={{
                          color: "#007bff",
                          textDecoration: "underline",
                        }} // Inline styles
                        onMouseEnter={(e) => (e.target.style.color = "#0056b3")} // Darker shade on hover
                        onMouseLeave={(e) => (e.target.style.color = "#007bff")} // Return to original color
                      >
                        Go Home Page
                      </Link>
                    </div>
                  </p>
                ) : (
                  // Render cart items here if available
                  <div>Your cart items will be displayed here.</div>
                )}
              </div>
            )}
          </div>
          <div className="mt-6 lg:mt-0 lg:w-1/3">
            <div className="bg-gray-50 border border-gray-200 rounded-lg shadow-sm p-4">
              <h3 className="text-lg font-semibold text-gray-900">
                Order Summary
              </h3>
              <div className="mt-4">
                <p className="text-xl font-bold text-gray-900">
                  Total: ${calculateTotalPrice()}
                </p>
              </div>
              <button
                className="mt-4 w-full bg-green-500 text-white rounded-md py-2 hover:bg-green-600 transition-colors"
                onClick={handleCheckoutClick}
              >
                Proceed to Checkout
              </button>
              <Link
                to="/"
                className="mt-4 w-full bg-gray-800 text-white rounded-md py-2 text-center hover:bg-gray-600 transition-colors block"
              >
                Go Back Home
              </Link>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default ShoppingCart;
