/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "./Customer.css"; // Ensure this file contains any additional styling if needed
import Navbar from "../components/Navbar";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Import default styles
import { FaChevronDown } from "react-icons/fa"; // Importing a downwards arrow icon
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io"; // Add arrow icons if needed
import { AiOutlineLoading3Quarters } from "react-icons/ai"; // Optional loading icon for better UX
import { FaSpinner } from "react-icons/fa"; // For loading spinner
const Customer = () => {
  const { code } = useParams();
  const [products, setProducts] = useState([]);
  const [textBoxQuantity, setTextBoxQuantity] = useState(0); // Initialize quantity state to 0
  const [amountPayable, setAmountPayable] = useState(0); // Initialize quantity state to 0
  const [errorMessage, setErrorMessage] = useState("");
  const inputRef = useRef(null); // Create a ref for the input
  const [filteredProducts, setFilteredProducts] = useState([]); // For filtered results
  const [selectedCategory, setSelectedCategory] = useState(""); // State to track the selected category
  const uniqueCategories = [
    ...new Set(products.map((product) => product.product_category)),
  ]; // Extract unique categories
  const [loadingProductCode, setLoadingProductCode] = useState(null); // State to track which product is loading
  const [selectedProduct, setSelectedProduct] = useState({
    product_name: "",
    product_id: "",
    product_description: "",
    product_price: "",
    product_code: "",
  }); // For filtered results
  const [logos, setLogos] = useState([]);
  const [searchInput, setSearchInput] = useState(""); // State to track the search input
  const domainname = "http://www.just-one-price.com:3000";
  let user_id = localStorage.getItem("user_id");
  let token = localStorage.getItem("token");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [images, setImages] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // Track loading state
  const [selectedUserCode, setSelectedUserCode] = useState(null);
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus(); // Focus the input when the product is selected
    }
  }, [selectedProduct]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const logosToShow = 6; // Number of logos to display at a time
  // Toggle dropdown visibility
  const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);
  const handleInputChange = (e) => {
    changeTextBoxQuantity(e.target.value);
  };
  const logoContainerRef = React.useRef(null); // Reference to the logo container

  const scrollLeft = () => {
    if (logoContainerRef.current) {
      logoContainerRef.current.scrollBy({
        left: -logoContainerRef.current.offsetWidth, // Scroll by the width of the container
        behavior: "smooth",
      });
      setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0)); // Prevent going below 0
    }
  };

  const scrollRight = () => {
    if (logoContainerRef.current) {
      logoContainerRef.current.scrollBy({
        left: logoContainerRef.current.offsetWidth, // Scroll by the width of the container
        behavior: "smooth",
      });
      setCurrentIndex((prevIndex) =>
        Math.min(prevIndex + 1, Math.floor(logos.length / logosToShow))
      ); // Prevent exceeding available logos
    }
  };

  const isLeftArrowDisabled = currentIndex === 0;
  const isRightArrowDisabled =
    currentIndex === Math.floor(logos.length / logosToShow);
  const changeTextBoxQuantity = (value) => {
    let num = parseInt(value);

    if (isNaN(num) || num < 0) {
      // If input is not a number or is negative
      setErrorMessage("Please input a valid quantity.");
    } else {
      // Valid quantity input
      setTextBoxQuantity(num);
      setErrorMessage(""); // Clear any previous error message

      // Update total amount payable
      const price = parseFloat(selectedProduct.product_price);
      const amount = Math.round(num * price * 100) / 100;
      setAmountPayable(amount);
    }
  };
  const isAuthenticated = () => {
    const token = localStorage.getItem("token");
    const userId = localStorage.getItem("user_id");
    return !!(token && userId); // Return true if both are present
  };

  // Check if the user is authenticated
  const isLoggedIn = isAuthenticated();
  const incrementTextBoxQuantity = (e) => {
    changeTextBoxQuantity(textBoxQuantity + 1);
  };

  const decrementTextBoxQuantity = (e) => {
    if (textBoxQuantity > 0) {
      changeTextBoxQuantity(textBoxQuantity - 1);
    }
  };

  const addToShoppingCart = async () => {
    const user_id = localStorage.getItem("user_id");
    const token = localStorage.getItem("token");

    const response = await fetch(`${domainname}/product/addToShoppingCart`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user_id,
        token,
        product_id: selectedProduct.product_id,
        quantity: textBoxQuantity,
        price: selectedProduct.product_price,
        discount: "0",
      }),
    });

    if (response.ok) {
      toast.success("Item added to cart successfully!");
      return true; // Return true if the response is OK
    } else {
      toast.error("Failed to add item to cart. Please try again.");
      return false; // Return false if the response is not OK
    }
  };

  const ImageModal = ({ images, isOpen, onClose }) => {
    const [mainImageIndex, setMainImageIndex] = useState(0);
    const handlePrevImage = () => {
      setMainImageIndex((prevIndex) =>
        prevIndex === 0 ? images.length - 1 : prevIndex - 1
      );
    };

    // Handler for the next image
    const handleNextImage = () => {
      setMainImageIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    };

    if (!isOpen) return null; // Don't render if the modal is closed

    return (
      <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
        <div className="bg-white p-6 rounded-lg max-w-4xl w-full max-h-screen overflow-y-auto shadow-lg">
          <div className="flex justify-between items-center mb-4">
            <button
              onClick={onClose}
              className="text-red-500 hover:text-red-700 transition-colors text-xl"
            >
              Close
            </button>
          </div>

          <div className="text-center mb-6">
            <h2 className="text-3xl font-bold text-gray-800 mb-2">
              {selectedProduct.product_name}
            </h2>
            <p className="text-gray-700 text-base mb-4">
              {selectedProduct.product_description}
            </p>
            <div className="text-2xl font-semibold text-gray-900">
              Price:
              <span className="text-blue-600 ml-2">
                ${selectedProduct.product_price}
              </span>
            </div>
          </div>
          <div className="relative flex justify-center items-center">
            <img
              className="h-auto max-w-full rounded-lg object-cover main-image" // Adjust height as desired and use object-cover
              src={images[mainImageIndex]} // Use the mainImage state for the main image
              alt={`Main product image ${mainImageIndex + 1}`} // Use index for alt text
            />
            <button
              className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-gray-800 text-white p-2 rounded-full m-2"
              onClick={handlePrevImage}
            >
              &#10094; {/* Left Arrow */}
            </button>
            <button
              className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-gray-800 text-white p-2 rounded-full m-2"
              onClick={handleNextImage}
            >
              &#10095; {/* Right Arrow */}
            </button>
          </div>

          {/* Centered Quantity Selector */}
          <div className="flex flex-col items-center mb-4">
            <label
              htmlFor="quantity-input"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Choose Quantity:
            </label>
            <div className="flex items-center">
              <button
                type="button"
                id="decrement-button"
                onMouseDown={(e) => {
                  e.preventDefault(); // Prevent button focus
                  decrementTextBoxQuantity(e);
                }}
                className="bg-gray-100 hover:bg-gray-200 border border-gray-300 rounded-l-lg p-3 h-11 focus:ring-2 focus:ring-blue-300"
              >
                <svg
                  className="w-3 h-3 text-gray-900"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 18 2"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M1 1h16"
                  />
                </svg>
              </button>
              <input
                type="text"
                value={textBoxQuantity}
                onChange={(e) => handleInputChange(e)}
                id="quantity-input"
                ref={inputRef}
                className="bg-gray-50 border-t border-b border-gray-300 h-11 text-center text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-20 mx-2"
                required
              />
              <button
                type="button"
                id="increment-button"
                onMouseDown={(e) => {
                  e.preventDefault(); // Prevent button focus
                  incrementTextBoxQuantity(e);
                }}
                className="bg-gray-100 hover:bg-gray-200 border border-gray-300 rounded-r-lg p-3 h-11 focus:ring-2 focus:ring-blue-300"
              >
                <svg
                  className="w-3 h-3 text-gray-900"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 18 18"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M9 1v16M1 9h16"
                  />
                </svg>
              </button>
            </div>

            {errorMessage && (
              <p className="text-red-500 mt-2">{errorMessage}</p>
            )}
            <p className="mt-2 text-sm text-gray-500">
              Please select a quantity to add to your shopping cart.
            </p>
          </div>

          <div className="mb-4 text-lg font-semibold">
            Total Amount Payable: ${amountPayable}
          </div>
          <div>
            <button
              onClick={async (e) => {
                const success = await addToShoppingCart(); // Call the function and store the result
                if (success) {
                  setIsModalOpen(false); // Close the modal if successful
                }
              }}
              className="mt-4 inline-flex items-center py-2 px-4 text-sm font-medium text-white bg-blue-600 rounded-lg hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300"
            >
              Add to Shopping Cart
            </button>
          </div>
        </div>
      </div>
    );
  };
  const fetchImagesAndShowModal = async (productCode) => {
    user_id = localStorage.getItem("user_id");
    token = localStorage.getItem("token");
    setLoadingProductCode(productCode);
    if (!(user_id && token)) {
      toast.error("User is not authenticated.");
      return;
    }
    try {
      if (!(user_id == null || token == null)) {
        const response = await fetch(
          `${domainname}/product/products/images/allImages/default/${productCode}`,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
            },
          }
        );

        const response1 = await fetch(
          `${domainname}/product/${productCode}/${user_id}/${encodeURIComponent(
            token
          )}`,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
            },
          }
        );
        // alert("hello");
        // alert(response1);
        if (response.ok && response1.ok) {
          const imageData = await response.json();
          const response1Data = await response1.json();
          // Map base64 images to URLs that can be rendered in the <img> tags
          const imageUrls = imageData.map((image) => {
            return `data:image/png;base64,${image.image_binary}`; // Ensure the MIME type matches your image (e.g., image/png or image/jpeg)
          });

          setImages(imageUrls); // Set the fetched images to display

          if (response1Data.products) {
            setSelectedProduct(response1Data.products[0]);
            setIsModalOpen(true); // Open the modal
          } else {
            console.error("Product data not found");
          }
        }
      }
    } catch (error) {
      console.error("Error in opening dialog box:", error);
    }
    setLoadingProductCode(null);
  };
  const handleCategoryChange = (category) => {
    // Filter products based on the selected category
    const filtered = category
      ? products.filter((product) => product.product_category === category)
      : products; // Show all products if no category is selected

    setFilteredProducts(filtered);
  };
  const handleLogoClick = async (userCode) => {
    setSelectedUserCode(userCode); // Set selected user code
    setIsLoading(true); // Set loading state to true

    try {
      // Fetch products for the clicked user code
      const url = `${domainname}/product/products/${userCode}/${user_id}/${encodeURIComponent(
        token
      )}`;
      const response = await fetch(url);
      const data = await response.json();

      if (data.products) {
        setFilteredProducts(data.products); // Set filtered products based on the user code
      } else {
        setFilteredProducts([]); // If no products are found for this user code
      }
    } catch (error) {
      console.error("Error loading products:", error);
      setFilteredProducts([]); // In case of error, set filtered products to an empty array
    } finally {
      setIsLoading(false); // Set loading state to false after fetching products
    }
  };
  useEffect(() => {
    const loadProducts = async () => {
      try {
        let url;
        if (code != null) {
          url =
            code.length === 10
              ? `${domainname}/product/products/${code}/${user_id}/${encodeURIComponent(
                  token
                )}`
              : `${domainname}/product/${code}/${user_id}/${encodeURIComponent(
                  token
                )}`;
        } else {
          if (user_id && token) {
            url = `${domainname}/product/getTopproducts/${user_id}/${encodeURIComponent(
              token
            )}`;
          } else {
            url = `${domainname}/product/getTopproducts`;
          }
        }

        const response = await fetch(url);
        const data = await response.json();
        if (data.products) {
          setProducts(data.products);

          setFilteredProducts(data.products); // Initially show all products
        }
      } catch (error) {
        console.error("Error loading products:", error);
      }
    };

    loadProducts();
  }, [code]);
  useEffect(() => {
    const fetchLogos = async () => {
      try {
        const response = await fetch(
          `${domainname}/user/users/role/partner/all/logos`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch user logos");
        }
        const data = await response.json();
        setLogos(data.logos);
      } catch (error) {
        console.error(error);
      }
    };

    fetchLogos();
  }, []);
  useEffect(() => {
    // Simulate fetching logos from an API
    setTimeout(() => {
      // Replace this with actual fetching logic

      setIsLoading(false); // Set loading to false after fetching logos
    }, 2000); // Simulate a 2-second delay for loading
  }, []);

  // Handle search form submission
  const handleSearch = (e) => {
    e.preventDefault();

    // Trim the input to remove whitespace from both ends
    const trimmedInput = searchInput.trim();

    // Check if the trimmed input is empty
    if (!trimmedInput && !selectedCategory) {
      window.location.reload();
      return;
    }

    // Check if the trimmed input contains only non-alphanumeric characters
    if (!/[a-zA-Z0-9]/.test(trimmedInput)) {
      alert("Please enter a valid search term (not just spaces or symbols)."); // Show alert
      return; // Stop further execution
    }

    // Filter products based on name or description
    const filtered = products.filter(
      (product) =>
        product.product_name
          .toLowerCase()
          .includes(trimmedInput.toLowerCase()) ||
        product.product_description
          .toLowerCase()
          .includes(trimmedInput.toLowerCase()) ||
        product.product_code.toLowerCase().includes(trimmedInput.toLowerCase())
    );

    setFilteredProducts(filtered); // Update filtered products
  };
  return (
    <div className="bg-white">
      <Navbar />

      <div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8 mt-16">
        <div className="relative flex justify-start lg:justify-center mt-4 lg:mt-0">
          {" "}
          {/* Make this relative so that dropdown can position correctly */}
          <button
            onClick={toggleDropdown}
            className="inline-flex items-center px-6 py-3 rounded-full bg-blue-600 text-white shadow-lg hover:bg-blue-500 transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-600"
          >
            Categories
            {/* Rotate the arrow when the dropdown is open */}
            <FaChevronDown
              className={`ml-2 text-white transition-transform duration-300 ease-in-out ${
                isDropdownOpen ? "rotate-180" : ""
              }`}
            />
          </button>
          {/* Dropdown menu */}
          {isDropdownOpen && (
            <div className="absolute top-full mt-2 w-64 bg-white shadow-lg rounded-lg z-20">
              <button
                onClick={() => {
                  setSelectedCategory("");
                  handleCategoryChange("");
                  setIsDropdownOpen(false);
                }}
                className={`block px-6 py-3 w-full text-left rounded-t-lg transition duration-300 ease-in-out ${
                  selectedCategory === ""
                    ? "bg-blue-600 text-white"
                    : "bg-transparent text-blue-600"
                } hover:bg-blue-100 focus:outline-none focus:ring-2 focus:ring-blue-600`}
              >
                All Categories
              </button>
              {uniqueCategories
                .filter((category) => category)
                .map((category, index) => (
                  <button
                    key={index}
                    onClick={() => {
                      setSelectedCategory(category);
                      handleCategoryChange(category);
                      setIsDropdownOpen(false); // Close the dropdown after selection
                    }}
                    className={`block px-6 py-3 w-full text-left transition duration-300 ease-in-out ${
                      selectedCategory === category
                        ? "bg-blue-600 text-white"
                        : "bg-transparent text-blue-600"
                    } hover:bg-blue-100 focus:outline-none focus:ring-2 focus:ring-blue-600`}
                  >
                    {category}
                  </button>
                ))}
            </div>
          )}
        </div>

        <div className="logo-gallery-container relative mt-20 lg:mt-32 ">
          {/* Left Arrow Button */}
          <button
            onClick={scrollLeft}
            disabled={isLeftArrowDisabled || isLoading}
            className={`absolute left-2 top-1/2 transform -translate-y-1/2 bg-gray-300 p-2 rounded-full shadow-md hover:bg-gray-400 transition duration-300 ${
              isLeftArrowDisabled || isLoading
                ? "opacity-50 cursor-not-allowed"
                : ""
            }`}
          >
            {isLoading ? (
              <FaSpinner className="text-3xl text-gray-700 animate-spin" />
            ) : (
              <IoIosArrowBack className="text-lg text-gray-700" />
            )}
          </button>

          <div
            className="logo-container -mt-16 mb-16 overflow-x-hidden py-4 scroll-smooth"
            ref={logoContainerRef}
          >
            <div className="flex gap-6">
              {isLoading ? (
                // Skeleton Loader for logos
                <>
                  {Array.from({ length: logosToShow }).map((_, index) => (
                    <div
                      key={index}
                      className="logo-item skeleton-logo cursor-pointer flex flex-col items-center bg-white p-4 rounded-lg shadow-md"
                    >
                      <div className="skeleton-logo-placeholder bg-gray-200 w-20 h-20 rounded-full mb-2"></div>
                      <div className="bg-gray-200 w-24 h-4 rounded-md"></div>
                    </div>
                  ))}
                </>
              ) : (
                logos
                  .slice(
                    currentIndex * logosToShow,
                    (currentIndex + 1) * logosToShow
                  )
                  .map((logo) => (
                    <div
                      key={logo.user_id}
                      className="logo-item cursor-pointer flex flex-col items-center bg-white p-4 rounded-lg shadow-md hover:shadow-lg transition duration-300 ease-in-out transform hover:scale-105"
                      onClick={() => handleLogoClick(logo.user_code)} // Pass user_code to handleLogoClick
                    >
                      {logo.logo_base64 ? (
                        <img
                          src={`data:image/png;base64,${logo.logo_base64}`}
                          alt={`${logo.username}'s logo`}
                          className="user-logo-placeholder mb-2 w-20 h-20 object-contain rounded-full"
                        />
                      ) : (
                        <div className="user-logo-placeholder mb-2 bg-gray-200 w-20 h-20 flex items-center justify-center rounded-full">
                          <p className="text-sm text-gray-500">No logo</p>
                        </div>
                      )}
                      <p className="text-sm text-gray-700">{logo.user_code}</p>
                    </div>
                  ))
              )}
            </div>
          </div>

          {/* Right Arrow Button */}
          <button
            onClick={scrollRight}
            disabled={isRightArrowDisabled || isLoading}
            className={`absolute right-2 top-1/2 transform -translate-y-1/2 bg-gray-300 p-2 rounded-full shadow-md hover:bg-gray-400 transition duration-300 ${
              isRightArrowDisabled || isLoading
                ? "opacity-50 cursor-not-allowed"
                : ""
            }`}
          >
            {isLoading ? (
              <FaSpinner className="text-3xl text-gray-700 animate-spin" />
            ) : (
              <IoIosArrowForward className="text-lg text-gray-700" />
            )}
          </button>
        </div>

        {/* Display filtered products based on selected user_code */}
        {selectedUserCode && (
          <div className="filtered-products mt-8">
            <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-6">
              {isLoading ? (
                <div className="loading-products flex justify-center items-center w-full py-8">
                  <AiOutlineLoading3Quarters className="animate-spin text-gray-500 text-3xl" />
                </div>
              ) : (
                <br />
              )}
            </div>
          </div>
        )}

        {/* Search Form */}
        <form
          onSubmit={handleSearch}
          className="flex items-center justify-center max-w-lg mx-auto -mt-10"
        >
          <label htmlFor="voice-search" className="sr-only">
            Search
          </label>
          <div className="relative w-full">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <svg
                className="w-5 h-5 text-gray-500"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 21 21"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M11.15 5.6h.01m3.337 1.913h.01m-6.979 0h.01M5.541 11h.01M15 15h2.706a1.957 1.957 0 0 0 1.883-1.325A9 9 0 1 0 2.043 11.89 9.1 9.1 0 0 0 7.2 19.1a8.62 8.62 0 0 0 3.769.9A2.013 2.013 0 0 0 13 18v-.857A2.034 2.034 0 0 1 15 15Z"
                />
              </svg>
            </div>
            <input
              type="text"
              id="voice-search"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
              className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5"
              placeholder="Search by name or description..."
            />
          </div>
          <button
            type="submit"
            className="ml-3 inline-flex items-center py-2.5 px-4 text-sm font-medium text-white bg-blue-600 rounded-lg hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300"
          >
            <svg
              className="w-5 h-5 mr-2"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 20 20"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
              />
            </svg>
            Search
          </button>
        </form>

        {/* Product Listing */}
        <div className="mt-8 grid grid-cols-1 gap-x-8 gap-y-12 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-10">
          {filteredProducts.map((product) => (
            <div
              onclick=""
              key={product.product_code}
              className="group relative shadow-lg rounded-lg p-4 bg-white hover:shadow-xl transition-shadow duration-300"
            >
              <div className="w-full h-60 overflow-hidden rounded-md bg-gray-200">
                <img
                  alt={product.product_name}
                  src={`${domainname}/product/products/images/first-image/default/${product.product_code}`}
                  className="h-full w-full object-cover object-center transition-transform duration-300 group-hover:scale-105"
                />
              </div>
              <div className="mt-4 flex flex-col items-center text-center">
                <h3 className="text-md font-semibold text-gray-800">
                  <a
                    href={`/product/${product.product_code}`}
                    className="hover:text-blue-500 transition-colors duration-200"
                  >
                    {product.product_name}
                  </a>
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                  {product.product_description}
                </p>
                {product.product_price ? (
                  <p className="mt-2 text-lg font-semibold text-gray-900">
                    ${product.product_price}
                  </p>
                ) : (
                  <p className="mt-2 text-lg font-semibold text-gray-900">
                    Please log in to see the price
                  </p>
                )}
              </div>
              <button
                onClick={async () => {
                  setLoadingProductCode(product.product_code);

                  await fetchImagesAndShowModal(product.product_code);

                  setLoadingProductCode(null);
                }}
                className={`mt-4 inline-flex items-center py-2 px-4 text-sm font-medium rounded-lg ${
                  isLoggedIn
                    ? "text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 transition-transform transform hover:scale-105"
                    : "text-gray-500 bg-gray-200 cursor-not-allowed"
                }`}
                disabled={
                  !isLoggedIn || loadingProductCode === product.product_code
                }
              >
                {loadingProductCode === product.product_code ? (
                  <span className="flex items-center">
                    <svg
                      className="animate-spin h-5 w-5 mr-3"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      />
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 0116 0H4z"
                      />
                    </svg>
                    Loading...
                  </span>
                ) : (
                  "Details"
                )}
              </button>
            </div>
          ))}
        </div>
      </div>
      <ImageModal
        images={images}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </div>
  );
};

export default Customer;
